<template>
    <div>
        <v-card-title class="headline">
            HISTÓRICO DE GOLES EN CONTRA
        </v-card-title>
        <v-container>
            <v-row>
                <v-col>
                    <v-alert v-if="mensaje" :type="tipoMensaje" dismissible>{{ mensaje }}</v-alert>

                    <v-data-table :headers="headers" :items="golesTablaBdContra" class="elevation-1">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.descripcion }}</td>
                                <td>{{ item.minuto }}</td>
                                <td>{{ getRivalName(item.idRivalGol) }}</td>
                                <td>{{ item.jornada }}</td>
                                <td>{{ item.portero }}</td>
                                <td>
                                    <v-btn @click="abrirDialogoEditar(item)" icon>
                                        <v-icon>fas fa-pencil-alt</v-icon>
                                    </v-btn>
                                    <v-btn disabled @click="mostrarConfirmacionEliminar(item.id)" icon>
                                        <v-icon>fas fa-trash-alt</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <!-- Diálogo para Editar Gol en Contra -->
            <v-dialog v-model="dialogEditar" max-width="600px">
                <v-card>
                    <v-card-title class="headline">Editar Gol en Contra</v-card-title>
                    <v-card-text>
                        <v-form ref="formEditar" v-model="valid">
                            <v-select v-model="golEdicion.descripcion" :items="descripcionOptions" label="Descripción"
                                item-text="text" item-value="value"></v-select>
                            <v-text-field v-model="golEdicion.minuto" label="Minuto"></v-text-field>
                            <v-select readonly disabled v-model="golEdicion.idRivalGol" :items="rivales" item-value="id"
                                item-text="nombre" label="Rival"></v-select>
                            <v-text-field readonly disabled v-model="golEdicion.jornada" label="Jornada"></v-text-field>
                            
                            <v-select v-model="golEdicion.portero" :items="jugadores" item-value="apodo"
                                item-text="apodo" label="Portero"></v-select>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogEditar = false">Cancelar</v-btn>
                        <v-btn color="green darken-1" text @click="actualizarGol">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        dialogEditar: false,
        mensaje: '',
        tipoMensaje: '',
        golesTablaBdContra: [],
        golEdicion: {},
        valid: false, // Propiedad para el estado del formulario
        headers: [
          { text: 'Descripción', value: 'descripcion' },
          { text: 'Minuto', value: 'minuto' },
          { text: 'Rival', value: 'idRivalGol' }, // Para mostrar el nombre del rival
          { text: 'Jornada', value: 'jornada' },
          { text: 'Portero', value: 'portero' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ],
        rivales: [], // Lista de rivales
        jugadores: [],
          descripcionOptions: [            // Define las opciones aquí
              'Penalti',
              'Libre-directo',
              'Balón Parado',
              'Centro lateral',
              'Tras presión',
              'Contrataque',
              'Ata-posicional',
              'Mano-mano',
              'Disparo lejano',
              'Pase atras'
          ]
      };
    },
    methods: {
      async fetchRivales(codigoUsuario) {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        try {
          const responseRivales = await fetch(`${API_BASE_URL}/rivales/${codigoUsuario}/codigo`);
          if (!responseRivales.ok) {
            throw new Error('Error al cargar los rivales.');
          }
          this.rivales = await responseRivales.json();
        } catch (error) {
          console.error(error);
          this.mensaje = 'Error al cargar los rivales.';
          this.tipoMensaje = 'error';
        }
      },

      async cargarJugadores(codigoUsuario) {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        try {
          const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
          if (!responseJugadores.ok) {
            throw new Error("No se pudo obtener la lista de jugadores");
          }
          this.jugadores = await responseJugadores.json();
          
        } catch (error) {
          console.error("Error al cargar jugadores:", error);
        }
      },
      
      async fetchGolesData() {
        try {
            debugger;
          const usuarioId = sessionStorage.getItem('userId');
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
          
          // Obtener el código de usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error('No se pudo obtener el código de usuario');
          }
          const codigoUsuario = await responseCodigo.text();
          
          // Obtener los goles en contra filtrados por el código de usuario
          const responseGolesContra = await fetch(`${API_BASE_URL}/golrival/codigo/${codigoUsuario}`);
          if (!responseGolesContra.ok) {
            throw new Error('Error fetching data');
          }
          this.golesTablaBdContra = await responseGolesContra.json();
  
          // Obtener los rivales
          await this.fetchRivales(codigoUsuario);
          await this.cargarJugadores(codigoUsuario);
          
        } catch (error) {
          console.error(error);
          this.mensaje = 'Error al cargar los datos.';
          this.tipoMensaje = 'error';
        }
      },
  
      getRivalName(id) {
        const rival = this.rivales.find(r => r.id === id);
        return rival ? rival.nombre : 'Desconocido';
      },
      
      abrirDialogoEditar(gol) {
        this.golEdicion = { ...gol }; // Crear una copia del gol para editar
        this.dialogEditar = true; // Abrir el diálogo de edición
      },
  
      async actualizarGol() {
        debugger;
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        try {
          const response = await fetch(`${API_BASE_URL}/golrival/${this.golEdicion.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.golEdicion)
          });
  
          if (!response.ok) {
            throw new Error('Error al actualizar el gol');
          }
  
          this.mensaje = 'Gol en contra actualizado correctamente.';
          this.tipoMensaje = 'success';
          this.dialogEditar = false;
          this.fetchGolesData(); // Volver a cargar los datos
        } catch (error) {
          console.error(error);
          this.mensaje = 'Error al actualizar el gol.';
          this.tipoMensaje = 'error';
        }
      },
  
      async eliminarGol(id) {
        /*const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        try {
          const response = await fetch(`${API_BASE_URL}/golrival/${id}`, {
            method: 'DELETE'
          });
  
          if (!response.ok) {
            throw new Error('Error al eliminar el gol');
          }
  
          this.mensaje = 'Gol en contra eliminado correctamente.';
          this.tipoMensaje = 'success';
          this.fetchGolesData(); // Volver a cargar los datos
        } catch (error) {
          console.error(error);
          this.mensaje = 'Error al eliminar el gol.';
          this.tipoMensaje = 'error';
        }*/
      },
    },
    async mounted() {
        await this.fetchGolesData();
        
    }
  };
  </script>
  
  <style scoped>
  /* Agrega estilos aquí si es necesario */
  </style>
  