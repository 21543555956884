<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title class="headline">
      Registro de Usuario
    </v-card-title>

    <v-card-text>
      <usuario-form ref="UsuarioForm" :equipos="equipos"></usuario-form>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="submitForm" color="green">
        Registrar
      </v-btn>
      <v-btn @click="redirectToLogIn" color="red">
        Volver LogIn
      </v-btn>
      <v-btn  color="primary" outlined @click="redirectCrearEquipo">
        <v-icon>mdi-plus</v-icon>
        Nuevo equipo
      </v-btn>
    </v-card-actions>

    <v-alert
      v-if="alertMessage"
      dense
      
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>

    <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-card-title class="headline">Usuario Registrado</v-card-title>
          <v-card-text>
            <div>
              <p><strong>Nombre:</strong> {{ usuarioCreado.nombre }}</p>
              <p><strong>Apellido:</strong> {{ usuarioCreado.apellido }}</p>
              <p><strong>Username:</strong> {{ usuarioCreado.username }}</p>
              <p><strong>Equipo:</strong> {{ usuarioCreado.equipo }}</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="cerrarDialog">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-card>
</template>


<script>
import UsuarioForm from '../../components/UsuarioComponents/UsuarioForm.vue';

export default {
  components: {
    UsuarioForm,
  },
  data() {
    return {
      alertMessage: null,
      alertType: null,
      equipos: [], // Inicializa la lista de equipos vacía
      dialog: false,
      usuarioCreado: {
          nombre: '',
          apellido: '',
          username: '',
          equipo: ''
        },
    };
  },
  methods: {
    cerrarDialog() {
      this.dialog = false;
      this.$refs.UsuarioForm.resetForm();
      this.$router.push({ name: 'LogIn'  });
    },

    validatePassword(password) {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
      return passwordPattern.test(password);
    },

    async submitForm() {
      const usuarioFormRef = this.$refs.UsuarioForm;
      const usuarioFormData = usuarioFormRef.getFormData();
      usuarioFormRef.activateRules();

      if (!usuarioFormData.nombre || !usuarioFormData.apellido || !usuarioFormData.username || !usuarioFormData.password || !usuarioFormData.confirmPassword || !usuarioFormData.codigo) {
        this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
        return;
      }

      if (!this.validatePassword(usuarioFormData.password)) {
        this.setAlert('La contraseña debe tener al menos 8 caracteres, incluir al menos una letra mayúscula, al menos una letra minúscula, al menos un carácter especial. y al menos un número.', 'error');
        return;
      }

      if (usuarioFormData.password !== usuarioFormData.confirmPassword) {
        this.setAlert('Las contraseñas no coinciden. Por favor, inténtelo de nuevo.', 'error');
        return;
      }

      if (!usuarioFormData.codigoManual) {
        this.setAlert('Por favor, introduzca el código manual.', 'error');
        return;
      }

      
      if (usuarioFormData.codigoManual !== String(usuarioFormData.codigo)) {
        this.setAlert('El código introducido no es el correspondiente al equipo seleccionado, introduce bien el código o selecciona bien el equipo. En caso de olvidar el código contactar con el administrador -> pablogomezperez01@gmail.com', 'error');
        return;
      }

      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        const response = await fetch(`${API_BASE_URL}/usuarios/crear`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nombre: usuarioFormData.nombre,
            apellido: usuarioFormData.apellido,
            username: usuarioFormData.username,
            password: usuarioFormData.password,
            rol: 'CLIENTE',
            codigo: usuarioFormData.codigo, // Incluye el ID del equipo en la solicitud POST
          }),
        });

        if (!response.ok) {
          let errorMessage = 'Error al registrar el usuario.';

          try {
            const responseText = await response.text();
            errorMessage = responseText || errorMessage;
          } catch (textError) {
            console.error('Error al obtener el texto de la respuesta:', textError);
          }

          this.setAlert(errorMessage, 'error');
          return;
        }
        const successMessage = `Usuario "${usuarioFormData.username}" registrado exitosamente.`;
        this.setAlert(successMessage, 'success');

        usuarioFormRef.resetForm();

        // Mostrar diálogo de éxito
        this.usuarioCreado= {
          nombre: usuarioFormData.nombre,
          apellido: usuarioFormData.apellido,
          username: usuarioFormData.username,
          equipo: usuarioFormData.codigo,
        },
        
        this.dialog = true;

      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
      }
    },
    redirectToLogIn() {
      this.$router.push({ name: 'LogIn' });
    },
    redirectCrearEquipo() {
      this.$router.push({ name: 'CrearEquipo' });
    },
    setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
    async cargarEquipos() {
      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        const response = await fetch(`${API_BASE_URL}/equipos`);
        if (!response.ok) {
          throw new Error('No se pudo obtener la lista de equipos');
        }
        const data = await response.json();
        // Añadir la clave 'nombreConCodigo' a cada objeto del array
        this.equipos = data.map(equipo => ({
          ...equipo,
          nombreCompuesto: `${equipo.nombre} > ${equipo.club}`
        }));

      } catch (error) {
        console.error('Error al cargar los equipos:', error);
        this.setAlert('Error al cargar los equipos. Por favor, intenta nuevamente.', 'error');
      }
    },

  },
  mounted() {
    this.cargarEquipos(); // Carga los equipos al montar el componente
  },
};
</script>

<style scoped>
/* Puedes agregar estilos específicos para tu componente aquí */
</style>
