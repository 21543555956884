<template>
    <div>
        <v-card-title class="headline">
            Información Portería
        </v-card-title>

        <v-container>
            <v-row>
                <!-- Tarjeta con el total de goles en contra -->
                <v-col cols="12">
                    <v-card class="pa-4 text-center">
                        <v-card-subtitle>
                            Goles en contra recibidos
                        </v-card-subtitle>
                        <div v-if="totalGolesContra > 0">
                            <v-chip color="red" large dark>
                                {{ totalGolesContra }}
                            </v-chip>
                        </div>
                        <div v-else>
                            Aún no hay goles en contra
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Tabla de goles encajados por jugador -->
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table :headers="headers" :items="golesPorPortero" class="elevation-1">
                            <template v-slot:no-data>
                                <v-alert>Sin datos disponibles.</v-alert>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            totalGolesContra: 0,
            golesPorPortero: [],
            headers: [
                { text: 'PORTERO', value: 'portero' },
                { text: 'TOTAL GOLES ENCAJADOS', value: 'totalGoles' },
            ],
        };
    },
    methods: {
        async fetchGolesData() {
            try {
                const usuarioId = sessionStorage.getItem('userId');
                const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

                // Obtener el código de usuario
                const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
                if (!responseCodigo.ok) {
                    throw new Error('No se pudo obtener el código de usuario');
                }
                const codigoUsuario = await responseCodigo.text();

                // Obtener los goles en contra filtrados por el código de usuario
                const responseGolesContra = await fetch(`${API_BASE_URL}/golrival/codigo/${codigoUsuario}`);
                if (!responseGolesContra.ok) {
                    throw new Error('Error fetching data');
                }
                const dataContra = await responseGolesContra.json();

                // Calcular total de goles en contra y goles por portero
                this.calcularGoles(dataContra);
            } catch (error) {
                console.error(error);
            }
        },
        calcularGoles(dataContra) {
            this.totalGolesContra = dataContra.length;

            // Contar goles encajados por portero
            const porteros = {};
            dataContra.forEach(goleada => {
                const portero = goleada.portero; // Asumiendo que 'portero' es la clave en cada objeto
                if (porteros[portero]) {
                    porteros[portero]++;
                } else {
                    porteros[portero] = 1;
                }
            });

            // Convertir el objeto a un array para la tabla
            this.golesPorPortero = Object.entries(porteros).map(([portero, totalGoles]) => ({
                portero,
                totalGoles,
            }));
        },
    },
    mounted() {
        this.fetchGolesData(); // Llamar a la función al montar el componente
    },
};
</script>

<style scoped>
/* Aquí puedes agregar estilos personalizados */
</style>
