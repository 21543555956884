<template>
    <v-container>
        <v-alert ref="alert"  :value="true" v-model="alert.show" :type="alert.type">
            {{ alert.message }}
        </v-alert>
        <v-card-title class="headline">
            Listado de Desconvocatorias
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn color="secondary" @click="toggleFilter" v-on="on">
                        <v-icon>fas fa-filter</v-icon>
                    </v-btn>
                </template>
                <span>FILTRO</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-form v-if="showFilter">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="filters.jugador" label="Jugador" clearable
                            @click:append="filters.jugador = null"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="filters.jornada" label="Jornada" clearable
                            @click:append="filters.jornada = null"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="filters.motivo" label="Motivo" clearable
                            @click:append="filters.motivo = null"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-data-table :headers="headers" :items="filteredDesconvocatorias" class="elevation-1">
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ getJugadorNombre(item.idJugador) }}</td>
                    <td>{{ item.jornada }}</td>
                    <td>{{ item.motivo }}</td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {
            headers: [
                { text: 'Jugador', value: 'jugador' },
                { text: 'Jornada', value: 'jornada' },
                { text: 'Motivo', value: 'motivo' },
            ],
            desconvocatorias: [],
            jugadores: [],
            confirmacionEliminar: false,
            desconvocatoriaAEliminarId: null,
            mensajeConfirmacion: '',
            alert: {
                show: false,
                type: 'success',
                message: '',
            },
            showFilter: false,
            filters: {
                motivo: '',
                jugador: '', 
                jornada: '', 
            },
        };
    },
    created() {
        const successMessage = this.$route.params.successMessage;
        if (successMessage) {
            this.success(successMessage);
        }
    },
    methods: {
        success(message) {
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = message;
        },
        error(message) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = message;
        },

        async getCodigoUsuarioLoguedIn() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const usuarioId = sessionStorage.getItem('userId');

            const response = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!response.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await response.text(); // Suponiendo que el código de usuario es un texto simple

            return codigoUsuario;
        },

        async cargarDesconvocatorias() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const codigoUsuario = await this.getCodigoUsuarioLoguedIn();

            fetch(`${API_BASE_URL}/desconvocatoria/codigo/${codigoUsuario}`)
                .then(response => response.json())
                .then(data => {
                    if (Array.isArray(data)) {
                        this.desconvocatorias = data;
                    } else {
                        this.error('Error al cargar los datos. Por favor, intenta nuevamente.');
                    }
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                    this.error('Error al cargar los datos. Por favor, intenta nuevamente.');
                });
        },
        cargarJugadores() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            fetch(`${API_BASE_URL}/jugadores`)
                .then(response => response.json())
                .then(data => {
                    this.jugadores = data;
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                });
        },
        getJugadorNombre(idJugador) {
            const jugador = this.jugadores.find(jugador => jugador.id === idJugador);
            return jugador ? jugador.apodo : 'Desconocido';
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        clearFilter() {
            this.filters.motivo = '';
        },
    },
    computed: {
        filteredDesconvocatorias() {
            let filtered = [...this.desconvocatorias];

            if (this.filters.motivo) {
                filtered = filtered.filter(desconvocatoria => desconvocatoria.motivo.includes(this.filters.motivo));
            }

            // Filtro por jugador
            if (this.filters.jugador) {
                filtered = filtered.filter(desconvocatoria => {
                    const jugadorNombre = this.getJugadorNombre(desconvocatoria.idJugador);
                    return jugadorNombre.toLowerCase().includes(this.filters.jugador.toLowerCase());
                });
            }

            // Filtro por jornada
            if (this.filters.jornada) {
                filtered = filtered.filter(desconvocatoria => desconvocatoria.jornada.toString().includes(this.filters.jornada));
            }

            return filtered;
        },
    },
    mounted() {
        this.cargarDesconvocatorias();
        this.cargarJugadores();
    },
};


</script>

<style scoped>
/* Estilos específicos del componente */
</style>
