<template>
    <v-container>
        <v-card-title class="headline text-center">
            Estadísticas de Jugadores
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn color="secondary" @click="toggleFilter" v-on="on">
                        <v-icon>fas fa-filter</v-icon>
                    </v-btn>
                </template>
                <span>FILTRO</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-form v-if="showFilter">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="filters.jugador" label="Buscar por Jugador" clearable
                            @click:append="filters.jugador = null"></v-text-field>
                    </v-col>
                    <!-- Aquí puedes agregar más campos de filtro según sea necesario -->
                </v-row>
            </v-form>
            <!-- Botones para mostrar u ocultar columnas -->
            <v-btn-toggle multiple class="mt-3">
                <v-btn v-for="header in headers" :key="header.value" outlined
                    @click="toggleColumnVisibility(header.value)" :active="columnVisibility[header.value]">
                    {{ header.text }}
                </v-btn>
            </v-btn-toggle>

        </v-card-text>
        <v-data-table :headers="filteredHeaders" :items="filteredJugadores" class="elevation-1">
            <template v-slot:item="{ item }">
                <tr>
                    <td v-if="columnVisibility.jugador"><strong>{{ item.jugador }}</strong></td>
                    <td v-if="columnVisibility.convocado">{{ item.convocado }}</td>
                    <td v-if="columnVisibility.desconvocado">{{ item.desconvocado }}</td>
                    <td v-if="columnVisibility.titular">{{ item.titular }}</td>
                    <td v-if="columnVisibility.suplente">{{ item.suplente }}</td>
                    <td v-if="columnVisibility.minutosMd"
                        :style="{ backgroundColor: getColorByMinutosMd(item.minutosMd) }">
                        {{ isNaN(parseFloat(item.minutosMd)) ? 'N/A' : parseFloat(item.minutosMd).toFixed(1) }}
                        <!-- Redondear a un decimal -->
                    </td>
                    <td v-if="columnVisibility.notaMd" :style="{ backgroundColor: getColorByNotaMd(item.notaMd) }">
                        {{ isNaN(parseFloat(item.notaMd)) ? 'N/A' : parseFloat(item.notaMd).toFixed(1) }}
                        <!-- Redondear a un decimal -->
                    </td>
                    <td v-if="columnVisibility.mvps" :style="{ backgroundColor: getColorByMVPS(item.mvps) }">{{
                        item.mvps }}</td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            headers: [
                { text: 'JUGADOR', value: 'jugador' },
                { text: 'CONVOCADO', value: 'convocado' },
                { text: 'DESCONVOCADO', value: 'desconvocado' },
                { text: 'TITULAR', value: 'titular' },
                { text: 'SUPLENTE', value: 'suplente' },
                { text: 'MINUTOS MD', value: 'minutosMd' },
                { text: 'NOTA MD', value: 'notaMd' },
                { text: 'MVPS', value: 'mvps' },
            ],
            jugadores: [],
            showFilter: false,
            columnVisibility: {
                jugador: true,
                convocado: true,
                desconvocado: true,
                titular: true,
                suplente: true,
                minutosMd: true,
                notaMd: true,
                mvps: true,
            },
            filters: {
                jugador: '',
                // Puedes agregar más filtros aquí según necesites
            },
        };
    },
    methods: {
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },

        async fetchEquipo() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const response = await fetch(`${API_BASE_URL}/equipos/${this.codigoUsuarioFiltro}`);
            if (response.ok) {
                const equipo = await response.json();
                this.handleEquipoData(equipo);
            } else {
                console.error('No se pudo obtener el equipo');
            }
        },
        handleEquipoData(equipo) {
            // Ajustar visibilidad de columnas basándonos en la categoría
            const categoria = equipo.categoria;
            if (categoria && categoria.startsWith('F8')) {
                this.columnVisibility.minutosMd = false;
            } else {
                this.columnVisibility.minutosMd = true;
            }
        },

        toggleColumnVisibility(column) {
            this.columnVisibility[column] = !this.columnVisibility[column];
        },

        getColorByMinutosMd(minutosMd) {
            if (minutosMd < 20) {
                return '#FFCDD2'; // Rojo claro
            } else if (minutosMd >= 20 && minutosMd <= 30) {
                return '#FFE0B2'; // Naranja claro
            } else if (minutosMd > 75) {
                return '#BBDEFB'; // Azul claro
            } else {
                return ''; // Sin color de fondo por defecto
            }
        },
        getColorByNotaMd(notaMd) {
            if (notaMd < 3) {
                return '#FFCDD2'; // Rojo claro
            } else if (notaMd > 4) {
                return '#C8E6C9'; // Verde claro
            } else {
                return ''; // Sin color de fondo por defecto
            }
        },
        getColorByMVPS(mvps) {
            if (mvps > 0) {
                return '#BBDEFB'; // Azul claro
            } else {
                return ''; // Sin color de fondo por defecto
            }
        },

        //COMIENZAN LOS FETCHES Y LA LOGICA PARA CARGAR LAS VARIABLES

        async getCodigoUsuario() {
            const usuarioId = sessionStorage.getItem('userId');
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

            this.codigoUsuarioFiltro = codigoUsuario;
        },

        async getJugadoresData() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            // Realizar la petición GET a la API 
            const responseJugadoresData = await fetch(`${API_BASE_URL}/inicioMenu/jugadoresData/${this.codigoUsuarioFiltro}`);
            if (!responseJugadoresData.ok) {
                alert("No se puedo obtener los datos de los jugadores.")
                throw new Error('No se pudo obtener los partidos del equipo');
            }

            const data = await responseJugadoresData.json();
            // Asignar los datos a la propiedad 'jugadores' 
            this.jugadores = data;

        }

    },
    computed: {
        filteredHeaders() {
            return this.headers.filter(header => this.columnVisibility[header.value]);
        },
        filteredJugadores() {
            let filtered = [...this.jugadores];

            // Filtrar por nombre del jugador
            if (this.filters.jugador) {
                filtered = filtered.filter(jugador => jugador.jugador.toLowerCase().includes(this.filters.jugador.toLowerCase()));
            }

            // Puedes agregar más lógica de filtrado aquí según necesites

            return filtered;
        },
    },
    async mounted() {
        await this.getCodigoUsuario();
        await this.getJugadoresData();
        this.fetchEquipo();
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
.table-fixed-width {
  width: 80%; /* Ajusta el ancho total de la tabla */
  table-layout: fixed; /* Hace que las columnas se ajusten al ancho total */
}

.column-jugador {
  width: 120px;
}

.column-convocado {
  width: 100px;
}

.column-desconvocado {
  width: 120px;
}

.column-titular {
  width: 100px;
}

.column-suplente {
  width: 100px;
}

.column-minutosMd {
  width: 120px;
}

.column-notaMd {
  width: 100px;
}

.column-mvps {
  width: 80px;
}
</style>