import { render, staticRenderFns } from "./ListadoPartidosRegistradosMenu.vue?vue&type=template&id=95c03aba&scoped=true"
import script from "./ListadoPartidosRegistradosMenu.vue?vue&type=script&lang=js"
export * from "./ListadoPartidosRegistradosMenu.vue?vue&type=script&lang=js"
import style0 from "./ListadoPartidosRegistradosMenu.vue?vue&type=style&index=0&id=95c03aba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c03aba",
  null
  
)

export default component.exports