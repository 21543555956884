<template>
    <div>
        <v-card-title class="headline">
            Listado faltas de asistencias a Entrenamientos
        </v-card-title>

        <v-container>
            <v-row>
                <v-col cols="3"> <!-- Columna para el filtro de jugador -->
                    <v-select v-model="filtroJugador" :items="jugadores" label="Selecciona un jugador"
                        clearable></v-select>
                </v-col>

                <v-col cols="3"> <!-- Columna para el filtro de motivo -->
                    <v-text-field v-model="filtroMotivo" label="Motivo" clearable></v-text-field>
                </v-col>

                <v-col cols="3"> <!-- Columna para el filtro de fecha -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fechaEntrenamiento" label="Fecha del entrenamiento"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaEntrenamiento" no-title @change="menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3"> <!-- Columna para el botón de limpiar filtros -->
                    <v-btn outlined @click="limpiarFiltros" color="grey" class="mt-2">
                        <v-icon>mdi-brush</v-icon>
                        <span>Limpiar filtros</span>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="2"> <!-- Columna para el botón de búsqueda -->
                    <v-btn @click="filtrarEntrenamientos" color="primary" class="mt-2">FILTRAR</v-btn>
                </v-col>

            </v-row>

            <v-row>
                <v-col>
                <!-- Tabla para mostrar faltas de asistencia -->
                <v-data-table :headers="headers" :items="asistenciasFiltradas" class="elevation-1">
                    <!-- Columnas personalizadas -->
                    <template v-slot:item.asistencia="{ item }">
                        <v-chip color="red" dark>Falta</v-chip>
                    </template>
                    <template v-slot:item.jugador="{ item }">
                        {{ item.jugador }}
                    </template>
                    <template v-slot:item.motivo="{ item }">
                        {{ item.motivo ? item.motivo : "" }}
                    </template>
                    <template v-slot:item.fecha="{ item }">
                        {{ item.fecha }}
                    </template>
                    <template v-slot:item.diaSemana="{ item }">
                        {{ item.diaSemana }}
                    </template>
                </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  

  <script>
  export default {
    data() {
      return {
        asistenciasEntrenamientos: [], // Datos de asistencias
        entrenamientos: [], // Datos de entrenamientos
        jugadores: [], // Lista de jugadores para el filtro
        filtroJugador: null, // Filtro para el jugador
        filtroMotivo: "", // Filtro para el motivo
        fechaEntrenamiento: null, // Filtro para la fecha
        menu: false, // Controlar el menú del selector de fecha
        headers: [
          { text: "Jugador", value: "jugador" },
          { text: "Motivo", value: "motivo" },
          { text: "Fecha", value: "fecha" },
          { text: "Día de la Semana", value: "diaSemana" },
          { text: "Asistencia", value: "asistencia" },
        ], // Definir las columnas de la tabla
      };
    },
    computed: {
      // Filtrar las asistencias con asistencia = false y añadir datos del entrenamiento
      asistenciasFiltradas() {
        return this.asistenciasEntrenamientos
          .filter(asistencia => !asistencia.asistencia) // Solo asistencias que son false
          .map(asistencia => {
            const entrenamiento = this.entrenamientos.find(e => e.id === asistencia.idEntrenamiento);
            return {
              ...asistencia,
              fecha: entrenamiento ? entrenamiento.fecha : "Desconocida",
              diaSemana: entrenamiento ? entrenamiento.diaSemana : "Desconocido",
            };
          })
          .filter(item => {
            // Aplicar los filtros de jugador, motivo y fecha
            const filtroJugadorOk = this.filtroJugador ? item.jugador === this.filtroJugador : true;
            const filtroMotivoOk = this.filtroMotivo ? item.motivo.includes(this.filtroMotivo) : true;
            const filtroFechaOk = this.fechaEntrenamiento ? item.fecha === this.fechaEntrenamiento : true;
  
            return filtroJugadorOk && filtroMotivoOk && filtroFechaOk;
          });
      },
    },
    methods: {
      // Cargar los entrenamientos
      async cargarEntrenamientos() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = sessionStorage.getItem("userId");
  
        try {
          // Obtener el código del usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error("No se pudo obtener el código de usuario");
          }
  
          const codigoUsuario = await responseCodigo.text();
  
          // Obtener los entrenamientos
          const responseEntrenamientos = await fetch(`${API_BASE_URL}/entrenamiento/codigo/${codigoUsuario}`);
          if (!responseEntrenamientos.ok) {
            throw new Error("No se pudieron obtener los entrenamientos");
          }
  
          const data = await responseEntrenamientos.json();
          this.entrenamientos = data;
  
          // Obtener la lista de jugadores (suponiendo que los nombres de los jugadores están en las asistencias)
          this.jugadores = [...new Set(this.asistenciasEntrenamientos.map(a => a.jugador))];
        } catch (error) {
          console.error("Error al cargar entrenamientos:", error);
        }
      },
      // Cargar las asistencias a los entrenamientos
      async cargarLineasEntrenamientos() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = sessionStorage.getItem("userId");
  
        try {
          // Obtener el código del usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error("No se pudo obtener el código de usuario");
          }
  
          const codigoUsuario = await responseCodigo.text();
  
          // Obtener las asistencias
          const responseAsistencias = await fetch(`${API_BASE_URL}/asistencias/codigo/${codigoUsuario}`);
          if (!responseAsistencias.ok) {
            throw new Error("No se pudieron obtener las asistencias a los entrenamientos");
          }
  
          const data = await responseAsistencias.json();
          this.asistenciasEntrenamientos = data;
  
          // Obtener la lista de jugadores al cargar las asistencias
          this.jugadores = [...new Set(this.asistenciasEntrenamientos.map(a => a.jugador))];
  
          console.log(this.asistenciasEntrenamientos);
        } catch (error) {
          console.error("Error al cargar las asistencias a los entrenamientos:", error);
        }
      },
      // Filtrar las asistencias
      filtrarEntrenamientos() {
        // Este método se puede dejar vacío porque el filtrado ya se maneja en la propiedad computada
        console.log("Filtrar entrenamientos...");
      },
      // Limpiar filtros
      limpiarFiltros() {
        this.filtroJugador = null;
        this.filtroMotivo = "";
        this.fechaEntrenamiento = null;
      },
    },
    async mounted() {
      // Cargar tanto las asistencias como los entrenamientos al montar el componente
      await this.cargarLineasEntrenamientos();
      await this.cargarEntrenamientos();
    },
  };
  </script>

<style scoped>
/* Aquí puedes agregar estilos personalizados */
</style>

