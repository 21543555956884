<template>
    <div>
      <v-card-title class="headline">
        Histórico de Entrenamientos
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="4"> <!-- Columna para el filtro de día de la semana -->
            <v-select v-model="filtroDia" :items="diasSemana" label="Selecciona un día de la semana" clearable></v-select>
          </v-col>
  
          <v-col cols="4"> <!-- Columna para el filtro de fecha -->
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                     transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="fechaEntrenamiento" label="Fecha del entrenamiento"
                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="fechaEntrenamiento" no-title @change="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          
          <v-col cols="3">
            <!-- Botón para limpiar la fecha -->
            <v-btn outlined @click="limpiarFecha" color="grey" class="mt-2">
              <v-icon>mdi-brush</v-icon> <!-- Cambia este icono si deseas otro -->
              <span>Limpiar filtro</span>
            </v-btn>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="2"> <!-- Columna para el botón de búsqueda -->
            <v-btn @click="filtrarEntrenamientos" color="primary" class="mt-2">FILTRAR</v-btn>
          </v-col>
        </v-row>
  
        <!-- Tabla de entrenamientos -->
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="entrenamientosFiltrados" class="elevation-1">
              
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.fecha }}</td>
                  <td>{{ item.diaSemana }}</td>
                  <td>
                    <v-chip :color="getColor(item.valoracion)" dark>
                      {{ item.valoracion }}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn @click="confirmarEliminacion(item)" icon>
                      <v-icon>mdi-delete</v-icon> <!-- Icono de basura -->
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
  
      <!-- Diálogo de Confirmación de Eliminación -->
      <v-dialog v-model="dialogEliminar" max-width="500px">
        <v-card>
          <v-card-title class="headline">Confirmar Eliminación</v-card-title>
          <v-card-text>
            <p>¿Estás seguro de que deseas eliminar el siguiente entrenamiento?</p>
            <ul>
              <li><strong>Fecha:</strong> {{ entrenamientoSeleccionado.fecha }}</li>
              <li><strong>Día de la Semana:</strong> {{ entrenamientoSeleccionado.diaSemana }}</li>
              <li><strong>Valoración:</strong> {{ entrenamientoSeleccionado.valoracion }}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelarEliminacion">Cancelar</v-btn>
            <v-btn color="green darken-1" text @click="eliminarEntrenamientoConfirmado">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        entrenamientos: [],
        entrenamientosFiltrados: [],
        filtroDia: null,
        fechaEntrenamiento: null,
        menu: false,
        dialogEliminar: false, // Control para mostrar u ocultar el diálogo
        entrenamientoSeleccionado: {}, // Guardar el entrenamiento seleccionado
        diasSemana: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
        headers: [
          { text: "Fecha", value: "fecha" },
          { text: "Día de la Semana", value: "diaSemana" },
          { text: "Valoración", value: "valoracion" },
          { text: "Acciones", value: "acciones", sortable: false }, // Nueva columna para acciones
        ],
      };
    },
    methods: {
      async cargarEntrenamientos() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = sessionStorage.getItem("userId");
  
        try {
          // Obtener el código de usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error("No se pudo obtener el código de usuario");
          }
  
          const codigoUsuario = await responseCodigo.text();
  
          // Realizar la petición GET para obtener entrenamientos
          const responseEntrenamientos = await fetch(`${API_BASE_URL}/entrenamiento/codigo/${codigoUsuario}`);
          if (!responseEntrenamientos.ok) {
            throw new Error("No se pudieron obtener los entrenamientos");
          }
  
          const data = await responseEntrenamientos.json();
          this.entrenamientos = data;
          this.entrenamientosFiltrados = this.entrenamientos;
  
        } catch (error) {
          console.error("Error al cargar entrenamientos:", error);
        }
      },
      filtrarEntrenamientos() {
        this.entrenamientosFiltrados = this.entrenamientos.filter(entrenamiento => {
          const coincideDia = this.filtroDia ? entrenamiento.diaSemana === this.filtroDia : true;
          const coincideFecha = this.fechaEntrenamiento ? entrenamiento.fecha === this.fechaEntrenamiento : true;
          return coincideDia && coincideFecha;
        });
      },
      limpiarFecha() {
        this.fechaEntrenamiento = null;
        this.filtrarEntrenamientos();
      },
      confirmarEliminacion(entrenamiento) {
        // Almacenar los detalles del entrenamiento seleccionado
        this.entrenamientoSeleccionado = entrenamiento;
        this.dialogEliminar = true; // Mostrar el diálogo
      },
      cancelarEliminacion() {
        // Cerrar el diálogo sin realizar ninguna acción
        this.dialogEliminar = false;
        this.entrenamientoSeleccionado = {}; // Limpiar la selección
      },
      async eliminarEntrenamientoConfirmado() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const id = this.entrenamientoSeleccionado.id; // Obtener el id del entrenamiento a eliminar
  
        try {
          const response = await fetch(`${API_BASE_URL}/entrenamiento/${id}`, {
            method: 'DELETE',
          });
  
          if (!response.ok) {
            throw new Error("Error al eliminar el entrenamiento");
          }
  
          // Actualizar la lista de entrenamientos filtrados
          this.entrenamientosFiltrados = this.entrenamientosFiltrados.filter(entrenamiento => entrenamiento.id !== id);
  
          // Cerrar el diálogo
          this.dialogEliminar = false;
          this.entrenamientoSeleccionado = {}; // Limpiar la selección
        } catch (error) {
          console.error("Error al eliminar el entrenamiento:", error);
        }
      },
      getColor(valoracion) {
        if (valoracion < 5) return 'red';
        else if (valoracion < 6) return 'grey';
        else if (valoracion < 8) return 'blue';
        else return 'green';
      },
    },
    mounted() {
      this.cargarEntrenamientos();
    },
  };
  </script>
  
  <style scoped>
  /* Aquí puedes agregar estilos personalizados */
  </style>
  