<template>
    <div>
        <v-card-title class="headline">
            HISTORICO DE GOLES A FAVOR
        </v-card-title>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="golesTablaBd" class="elevation-1">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.descripcion }}</td>
                                <td>{{ item.minuto }}</td>
                                <td>{{ obtenerJugadorGol(item.idJugadorGol) }}</td>
                                <td>{{ obtenerJugadorAsistente(item.idJugadorAsistente) }}</td>
                                <td>{{ obtenerRival(item.idRivalGol) }}</td>
                                <td>{{ item.jornada }}</td>
                                <td>
                                    <v-btn @click="abrirDialogoEditar(item)" icon>
                                        <v-icon>fas fa-pencil-alt</v-icon>
                                    </v-btn>
                                    <v-btn disabled @click="mostrarConfirmacionEliminar(item.id)" icon>
                                        <v-icon>fas fa-trash-alt</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <!-- Mensaje de Éxito o Error -->
            <v-alert v-if="mensaje" :type="tipoMensaje" dismissible>
                {{ mensaje }}
            </v-alert>

            <!-- Diálogo de Edición -->
            <v-dialog v-model="dialogEditar" max-width="600px">
                <v-card>
                    <v-card-title class="headline">Editar Gol</v-card-title>
                    <v-card-text>
                        <v-form ref="formEditar" v-model="valid">
                            <v-select v-model="golEdicion.descripcion" :items="descripcionOptions" label="Descripción"
                                item-text="text" item-value="value"></v-select>
                            <v-text-field v-model="golEdicion.minuto" label="Minuto"></v-text-field>
                            <v-select v-model="golEdicion.idJugadorGol" :items="jugadoresConAutogol" item-value="id"
                                item-text="apodo" label="Jugador que Marcó"></v-select>
                            <v-select v-model="golEdicion.idJugadorAsistente" :items="jugadoresConNadie" item-value="id"
                                item-text="apodo" label="Asistente"></v-select>
                            <v-select readonly disabled v-model="golEdicion.idRivalGol" :items="rivales" item-value="id"
                                item-text="nombre" label="Rival"></v-select>
                            <v-text-field readonly disabled v-model="golEdicion.jornada" label="Jornada"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="cancelarEdicion">Cancelar</v-btn>
                        <v-btn color="green darken-1" text @click="actualizarGol">Guardar</v-btn>
                    </v-card-actions>
                    <!-- Mensaje de Éxito o Error -->
                    <v-alert v-if="mensajeDialogEdit" :type="tipoMensajeDialogEdit" dismissible>
                        {{ mensajeDialogEdit }}
                    </v-alert>
                </v-card>
            </v-dialog>

            <!-- Diálogo de Confirmación de Eliminación -->
            <v-dialog v-model="dialogEliminar" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Confirmar Eliminación</v-card-title>
                    <v-card-text>
                        <p>¿Estás seguro de que deseas eliminar el gol?</p>
                        <p><strong>Descripción:</strong> {{ golSeleccionado.descripcion }}</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="cancelarEliminacion">Cancelar</v-btn>
                        <v-btn color="green darken-1" text @click="eliminarGolConfirmado">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        golesTablaBd: [],
        dialogEditar: false,
        dialogEliminar: false,
        golSeleccionado: {},
        golEdicion: {
          id: null,
          descripcion: '',
          minuto: '',
          idJugadorGol: null,
          idJugadorAsistente: null,
          idRivalGol: null,
          jornada: null,
        },
        jugadores: [],
        rivales: [],
        jugadoresConNadie: [],
        jugadoresConAutogol: [],
        mensaje: '', // Mensaje de éxito o error
        mensajeDialogEdit: '',
        tipoMensaje: '', // Tipo del mensaje: 'success' o 'error'
        tipoMensajeDialogEdit: '',
        valid: false,
        rules: {
          required: value => !!value || 'Requerido.',
        },
        headers: [
          { text: "Descripción", value: "descripcion" },
          { text: "Minuto", value: "minuto" },
          { text: "Jugador que Marcó", value: "idJugadorGol" },
          { text: "Asistente", value: "idJugadorAsistente" },
          { text: "Rival", value: "idRivalGol" },
          { text: "Jornada", value: "jornada" },
          { text: "Acciones", value: "acciones", sortable: false },
        ],
        descripcionOptions: [            // Define las opciones aquí
              'Penalti',
              'Libre-directo',
              'Balón Parado',
              'Centro lateral',
              'Tras presión',
              'Contrataque',
              'Ata-posicional',
              'Mano-mano',
              'Disparo lejano',
              'Pase atras'
          ],
      };
    },
    methods: {
      async cargarGoles() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = sessionStorage.getItem("userId");
  
        try {
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error("No se pudo obtener el código de usuario");
          }
  
          const codigoUsuario = await responseCodigo.text();
  
          const responseGoles = await fetch(`${API_BASE_URL}/goles/filtrar/${codigoUsuario}`);
          if (!responseGoles.ok) {
            throw new Error("Error fetching data");
          }
          const data = await responseGoles.json();
          this.golesTablaBd = data;
  
        } catch (error) {
          console.error("Error al cargar goles:", error);
        }
      },
      async cargarJugadores() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const codigoUsuario = await this.getCodigoUsuarioLoguedIn();
  
        try {
          const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
          if (!responseJugadores.ok) {
            throw new Error("No se pudo obtener la lista de jugadores");
          }
          this.jugadores = await responseJugadores.json();
          
          // Agregar opción "Nadie" para el asistente
          this.jugadoresConNadie = [...this.jugadores, { id: 0, apodo: 'Nadie' }];
          // Agregar opción "RIVAL (autogol)" para el jugador que marcó
          this.jugadoresConAutogol = [...this.jugadores, { id: 0, apodo: 'RIVAL (autogol)' }];
        } catch (error) {
          console.error("Error al cargar jugadores:", error);
        }
      },
      async cargarRivales() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const codigoUsuario = await this.getCodigoUsuarioLoguedIn();
  
        try {
          const responseRivales = await fetch(`${API_BASE_URL}/rivales/${codigoUsuario}/codigo`);
          if (!responseRivales.ok) {
            throw new Error("Error al cargar rivales");
          }
          this.rivales = await responseRivales.json();
        } catch (error) {
          console.error("Error al cargar rivales:", error);
        }
      },
      async getCodigoUsuarioLoguedIn() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = sessionStorage.getItem('userId');
  
        const response = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!response.ok) {
          throw new Error('No se pudo obtener el código de usuario');
        }
        const codigoUsuario = await response.text(); // Suponiendo que el código de usuario es un texto simple
  
        return codigoUsuario;
      },
      abrirDialogoEditar(gol) {
        this.golEdicion = { ...gol };
        this.dialogEditar = true;
        this.mensaje = ''; // Limpiar mensaje al abrir el diálogo
      },
      cancelarEdicion() {
        this.dialogEditar = false;
        this.golEdicion = { 
          id: null,
          descripcion: '',
          minuto: '',
          idJugadorGol: null,
          idJugadorAsistente: null,
          idRivalGol: null,
          jornada: null,
        };
      },
      async validarGolEdiccion(){
        
        this.mensajeDialogEdit = '';
        debugger;
          if (
              this.golEdicion.id !== undefined && // Permitir que el id sea 0
              this.golEdicion.descripcion && // Este campo no debe estar vacío
              this.golEdicion.minuto && // Este campo no debe estar vacío
              this.golEdicion.idJugadorGol !== undefined && // Permitir que idJugadorGol sea 0
              this.golEdicion.idJugadorAsistente !== undefined &&
              this.golEdicion.idRivalGol !== undefined && // Permitir que idRivalGol sea 0
              this.golEdicion.jornada !== undefined // Este campo no debe estar vacío
          ) {
              return true;
          } else {
              return false;
          }

      },
      async actualizarGol() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
  
        if (await this.validarGolEdiccion()) {
          try {
            const response = await fetch(`${API_BASE_URL}/goles/${this.golEdicion.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.golEdicion)
            });
  
            if (!response.ok) {
              throw new Error("Error al actualizar el gol");
            }
  
            // Actualizar la lista de goles
            const index = this.golesTablaBd.findIndex(g => g.id === this.golEdicion.id);
            if (index !== -1) {
              this.golesTablaBd.splice(index, 1, this.golEdicion);
            }
  
            // Mostrar mensaje de éxito
            this.mensaje = 'Gol actualizado correctamente.';
            this.tipoMensaje = 'success';
  
            // Cerrar el diálogo
            this.dialogEditar = false;
            this.cancelarEdicion();
  
          } catch (error) {
            console.error("Error al actualizar el gol:", error);
            this.mensaje = 'Error al actualizar el gol. Inténtalo nuevamente.';
            this.tipoMensaje = 'error';
          }
        }else {
            console.error("Error al actualizar el gol");
            this.mensajeDialogEdit = 'Error al actualizar el gol. Rellena TODOS los campos nuevamente.';
            this.tipoMensajeDialogEdit = 'error';
          }
      },
      mostrarConfirmacionEliminar(id) {
        const gol = this.golesTablaBd.find(g => g.id === id);
        this.golSeleccionado = gol;
        this.dialogEliminar = true; // Mostrar el diálogo de confirmación de eliminación
        this.mensaje = ''; // Limpiar mensaje al mostrar confirmación
      },
      cancelarEliminacion() {
        this.dialogEliminar = false;
        this.golSeleccionado = {}; // Limpiar la selección
      },
      async eliminarGolConfirmado() {
        /*const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const id = this.golSeleccionado.id; // Obtener el id del gol a eliminar
  
        try {
          const response = await fetch(`${API_BASE_URL}/goles/${id}`, {
            method: 'DELETE',
          });
  
          if (!response.ok) {
            throw new Error("Error al eliminar el gol");
          }
  
          // Actualizar la lista de goles
          this.golesTablaBd = this.golesTablaBd.filter(gol => gol.id !== id);
  
          // Mostrar mensaje de éxito
          this.mensaje = 'Gol eliminado correctamente.';
          this.tipoMensaje = 'success';
  
          // Cerrar el diálogo
          this.dialogEliminar = false;
          this.golSeleccionado = {}; // Limpiar la selección
        } catch (error) {
          console.error("Error al eliminar el gol:", error);
          this.mensaje = 'Error al eliminar el gol. Inténtalo nuevamente.';
          this.tipoMensaje = 'error';
        }*/
      },
      obtenerJugadorGol(id) {
        const jugador = this.jugadores.find(j => j.id === id);
        return jugador ? jugador.apodo : "Rival(Autogol)";
      },
      obtenerJugadorAsistente(id) {
        const asistente = this.jugadores.find(j => j.id === id);
        return asistente ? asistente.apodo : "Nadie";
      },
      obtenerRival(id) {
        const rival = this.rivales.find(r => r.id === id);
        return rival ? rival.nombre : "Desconocido";
      }
    },
    mounted() {
      this.cargarGoles();
      this.cargarJugadores();
      this.cargarRivales();
    },
  };
  </script>
  
  <style scoped>
  /* Aquí puedes agregar estilos personalizados */
  </style>
  