<template>
  <v-card class="mx-auto" max-width="400">
    <v-card-title class="headline">
      Observar Equipo
    </v-card-title>

    <v-card-text>
      <equipo-form ref="EquipoForm" :initialData="equipoData" :disableFields="true"></equipo-form>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="redirigirAActualizar" color="primary">
        Actualizar
      </v-btn>
      <v-btn @click="redirigirAEquipos" color="primary">
        Volver
      </v-btn>
    </v-card-actions>

    <v-alert
      v-if="alertMessage"
      dense
      
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
  </v-card>
</template>

<script>
import EquipoForm from '../../components/EquipoComponents/EquipoForm.vue';

export default {
  components: {
    EquipoForm,
  },
  data() {
    return {
      alertMessage: null,
      alertType: null,
      equipoData: null,
    };
  },
  methods: {
    async cargarDatosEquipo(codigo) {
      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/equipos/${codigo}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos del equipo');
        }
        const equipoData = await response.json();
        this.equipoData = equipoData;
      } catch (error) {
        console.error('Error al cargar los datos del equipo:', error);
        this.setAlert('Error al cargar los datos del equipo, pulse en volver e intentelo de nuevo.', 'error');
      }
    },
    redirigirAActualizar() {
      this.$router.push({ name: 'ActualizarEquipo', params: { codigo: this.equipoData.codigo } });
    },
    redirigirAEquipos() {
      this.$router.push('/equipos');
    },
    setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
  },
  created() {
    const equipoCodigo = this.$route.params.codigo;
    this.cargarDatosEquipo(equipoCodigo);
  },
};
</script>

<style scoped>
/* Estilos específicos para el componente */
</style>
