<template>

  <v-card class="mx-auto" max-width="400">

    <v-alert v-if="true" dismissible type="info" closable="true" icon="mdi-alert" class="text-h9">
      <template v-slot:title>
        Aviso Importante
      </template>
      <span>
        Revisa bien el entreno, los entrenos registrados luego no se podrán editar ni eliminar.
        Un mal dato registrado puede alterar los datos y llevarte a conclusiones erroneas.
      </span>
    </v-alert>

    

    <v-card-title class="headline">
      Nuevo entrenamiento
    </v-card-title>

    <v-card-text>
      <!-- Calendario para seleccionar la fecha del entrenamiento -->
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
        offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaEntrenamiento" label="Fecha del entrenamiento" prepend-icon="mdi-calendar"
            readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaEntrenamiento" no-title @change="menu = false"></v-date-picker>
      </v-menu>

      <!-- Selector para el día de la semana -->
      <v-select v-model="diaSemana" :items="diasSemana" label="Día de la semana" outlined></v-select>

      <!-- Iterar sobre jugadores con interruptor y selectores condicionales -->
      <div v-for="jugador in jugadores" :key="jugador.id" class="mt-4">
        <v-row>
          <v-col cols="4" class="d-flex align-center">
            <h4>{{ jugador.apodo }}</h4>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="jugador.asistencia" :label="jugador.asistencia ? 'Sí' : 'No'"
              @change="onSwitchChange(jugador)" />
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="5" v-if="!jugador.asistencia">
            <v-select v-model="jugador.motivo" :items="motivos" label=" Motivo"
              @change="onMotivoChange(jugador)"></v-select>
          </v-col>
        </v-row>

        <!-- Campo de texto adicional si selecciona "Otro" como motivo -->
        <v-row v-if="jugador.motivo === 'Otro'">
          <v-col cols="12">
            <v-text-field v-model="jugador.motivoOtro" label="Especificar motivo"
              placeholder="Especifica el motivo"></v-text-field>
          </v-col>
        </v-row>
      </div>

      <!-- Valoración general del entrenamiento -->
      <v-divider class="my-4"></v-divider>
      <v-card-title class="headline">Valoración general del entrenamiento</v-card-title>
      <v-rating hover  v-model="valoracionEntrenamiento" length="10" size="20"></v-rating>

      <v-alert v-if="alertMessage" dense :type="alertType">
      {{ alertMessage }}
    </v-alert>

      <!-- Botón registrar entrenamiento -->
      <v-row justify="center" class="mt-4">
        <v-btn color="primary" @click="validarEntrenamiento">Registrar entrenamiento</v-btn>
      </v-row>
    </v-card-text>

    <!-- Diálogo de confirmación -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmación de Entrenamiento</v-card-title>
        <v-card-text>
          <p><strong>Fecha del entrenamiento:</strong> {{ fechaEntrenamiento }}</p>
          <p><strong>Día de la semana:</strong> {{ diaSemana }}</p>

          <p><strong>Jugadores:</strong></p>
          <ul>
            <li v-for="jugador in jugadores" :key="jugador.id">
              <strong>{{ jugador.apodo }}:</strong> {{ jugador.asistencia ? 'Asistió' : 'No asistió' }}
              <span v-if="!jugador.asistencia">
                - Motivo: {{ jugador.motivo === 'Otro' ? jugador.motivoOtro : jugador.motivo }}
              </span>
            </li>
          </ul>

          <p><strong>Valoración del entrenamiento:</strong> {{ valoracionEntrenamiento }}/10</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="confirmarEntrenamiento">Confirmar</v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      fechaEntrenamiento: "",
      diaSemana: "",
      menu: false,
      dialog: false,
      valoracionEntrenamiento: 0,
      alertMessage: "",
      alertType: "",
      diasSemana: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
      motivos: [
        "Enfermedad",
        "Estudios",
        "Expulsión ent",
        "Falta transporte",
        "Lesión",
        "Viaje",
        "Otro",
        "Sin especificar"
      ],
      jugadores: []
    };
  },
  methods: {
    
    async cargarJugadores() {
      
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      const usuarioId = sessionStorage.getItem('userId');

      try {
        // Obtener el código de usuario
        const response = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!response.ok) {
          throw new Error('No se pudo obtener el código de usuario');
        }
        const codigoUsuario = await response.text(); // Suponiendo que el código de usuario es un texto simple

        // Obtener la lista de jugadores
        const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
        if (!responseJugadores.ok) {
          throw new Error('No se pudo obtener la lista de jugadores');
        }
        const data = await responseJugadores.json();

        // Mapear los datos obtenidos a la estructura deseada
        this.jugadores = data.map(jugador => ({
          id: jugador.id,             // Obtener el id del jugador
          nombre: jugador.nombre,       // Obtener el apodo del jugador para el nombre
          apodo: jugador.apodo,        // Mantener el apodo por si lo necesitas también
          asistencia: true,            // Inicializar la asistencia en true
          motivo: "",                  // Inicializar el motivo como cadena vacía
          codigo: jugador.codigo       // Obtener el código del jugador
        }));

        console.log(this.jugadores);
      } catch (error) {
        console.error(error.message);
      }
    },
    validarEntrenamiento() {
      this.alertMessage = ""; // Reiniciar el mensaje de alerta
      this.alertType= "";
      let isValid = true;

      // Validar fecha
      if (!this.fechaEntrenamiento) {
        this.alertMessage += "La fecha del entrenamiento es obligatoria. ";
        this.alertType= "error";
        isValid = false;
      }

      // Validar día de la semana
      if (!this.diaSemana) {
        this.alertMessage += "El día de la semana es obligatorio. ";
        this.alertType= "error";
        isValid = false;
      }

      // Validar valoración
      if (this.valoracionEntrenamiento === 0) {
        this.alertMessage += "La valoración es obligatoria. ";
        this.alertType= "error";
        isValid = false;
      }

      // Validar motivos para jugadores que no asistieron
      this.jugadores.forEach((jugador) => {
        if (!jugador.asistencia && !jugador.motivo) {
          this.alertMessage += `El jugador ${jugador.apodo} necesita un motivo. `;
          isValid = false;
          this.alertType= "error";
        }
      });

      // Validar motivos para jugadores que no asistieron
      this.jugadores.forEach((jugador) => {
        debugger;
        if (jugador.motivo === 'Otro') {

          if (jugador.motivoOtro.length > 200) {
            this.alertMessage += `El motivo para el jugador ${jugador.apodo} no puede exceder 200 caracteres. `;
            isValid = false;
            this.alertType = "error";
          }
        }
        });

      // Si las validaciones fallan, mostrar alerta
      if (!isValid) {
        return; // No continuar
      }

      // Si todo es válido, abrir el diálogo
      this.dialog = true;
    },
    async confirmarEntrenamiento() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      const usuarioId = sessionStorage.getItem('userId');

      try {
        // Obtener el código de usuario
        const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!responseCodigo.ok) {
          throw new Error('No se pudo obtener el código de usuario, ERROR 500');
        }
        const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

        // Crear el array de datos para el entrenamiento
        const datosEntrenamiento = {
          fecha: this.fechaEntrenamiento,
          diaSemana: this.diaSemana,
          valoracion: this.valoracionEntrenamiento,
          codigo: codigoUsuario // Agregar el código de usuario aquí
        };

        // Enviar la solicitud POST para crear el entrenamiento
        const responseEntrenamiento = await fetch(`${API_BASE_URL}/entrenamiento`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(datosEntrenamiento),
        });

        if (!responseEntrenamiento.ok) {
          throw new Error('Error al crear el entrenamiento, ERROR 500');
        }

        const idEntrenamiento = await responseEntrenamiento.json(); // Devuelve el ID del entrenamiento creado
        console.log('Entrenamiento creado con ID:', idEntrenamiento);

        // Intentar crear asistencias
        try {
            const responseAsistencias = await this.crearAsistencias(idEntrenamiento);
            
            // Verificar si la creación de asistencias fue exitosa
            if (responseAsistencias.status !== 201) {
                throw new Error('Error al crear las asistencias');
            }

            // Mensaje de éxito si todo sale bien
            this.alertMessage = "¡Entrenamiento y asistencias registradas con éxito!";
            this.alertType = "success";
            this.dialog = false;

        } catch (error) {
            console.error('Error al crear las asistencias, eliminando entrenamiento...');
            await this.eliminarEntrenamiento(idEntrenamiento);  // Eliminar el entrenamiento
            this.alertMessage = "Error al registrar las asistencias, el entrenamiento ha sido eliminado.";
            this.alertType = "error";
        }
      } catch (error) {
        console.error('Error:', error);
        this.alertMessage = "Hubo un error al registrar el entrenamiento completo. ERROR 500";
        this.alertType = "error";
      }
    },

    async crearAsistencias(idEntrenamientoValue) {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      const asistencias = this.jugadores.map(jugador => ({
        jugador: jugador.apodo,
        asistencia: jugador.asistencia,
        motivo: jugador.asistencia ? '' : (jugador.motivo === 'Otro' ? jugador.motivoOtro : jugador.motivo),
        codigo: jugador.codigo,
        idEntrenamiento: idEntrenamientoValue
      }));

      // Enviar la solicitud POST para crear las asistencias
      const response = await fetch(`${API_BASE_URL}/asistencias`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(asistencias),
      });

      return response;  // Retornar directamente el response para que el método confirmarEntrenamiento lo maneje
    },

    async eliminarEntrenamiento(idEntrenamiento) {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      const response = await fetch(`${API_BASE_URL}/entrenamiento/${idEntrenamiento}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error al eliminar el entrenamiento');
      }

      console.log('Entrenamiento eliminado con ID:', idEntrenamiento);
    },

    onSwitchChange(jugador) {
      if (jugador.asistencia) {
        jugador.motivo = "";
        jugador.motivoOtro = "";
      }
    },
    onMotivoChange(jugador) {
      if (jugador.motivo !== "Otro") {
        jugador.motivoOtro = "";
      }
    }
  },
  async mounted() {
    await this.cargarJugadores();
  },
};
</script>

<style scoped>
.v-card-title {
  color: #1e88e5;
  font-weight: bold;
  text-align: center;
}

.v-alert {
  margin-bottom: 20px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.rating {
  margin: 20px 0;
}
</style>

