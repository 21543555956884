<template>
    <v-card class="mx-auto" max-width="2000">
      <v-card-title class="headline">
        Actualizar Informe
      </v-card-title>
      <v-card-text>
        <informe-form ref="InformeForm" :initialData="informeData"></informe-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="actualizarInforme" color="primary">
          Actualizar
        </v-btn>
        <v-btn @click="volverAListado" color="primary">
          Volver
        </v-btn>
      </v-card-actions>
      <v-alert v-if="alertMessage"  dense :type="alertType">
        {{ alertMessage }}
      </v-alert>
    </v-card>
  </template>
  
  <script>
  import InformeForm from '../../../components/InformeComponents/InformeForm.vue';
  
  export default {
    components: {
      InformeForm,
    },
    data() {
      return {
        alertMessage: null,
        alertType: null,
        informeData: null,
      };
    },
    methods: {
      async cargarDatosInforme(id) {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/informes/${id}`);
        if (!response.ok) {
          this.setAlert('Error al cargar los datos del informe.', 'error');
          return;
        }
        this.informeData = await response.json();
      },
      async actualizarInforme() {

        const usuarioId = sessionStorage.getItem('userId');
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!responseCodigo.ok) {
          throw new Error('No se pudo obtener el código de usuario');
        }
        const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

        const informeFormRef = this.$refs.InformeForm;
        const informeFormData = informeFormRef.getFormData();
        // Agrega el código de usuario a informeFormData
        informeFormData.codigo = codigoUsuario;

        if (!informeFormData.nombre || !informeFormData.descripcion) {
          this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
          return;
        }
  
        const id = this.informeData.id;
        const response = await fetch(`${API_BASE_URL}/informes/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(informeFormData),
        });
  
        if (!response.ok) {
          const errorMessage = await response.text();
          this.setAlert(errorMessage || 'Error al actualizar el informe.', 'error');
          return;
        }
  
        this.setAlert('Informe actualizado exitosamente.', 'success');
        this.$router.push({ name: 'Informes' });
      },
      volverAListado() {
        this.$router.push({ name: 'Informes' });
      },
      setAlert(message, type) {
        this.alertMessage = message;
        this.alertType = type;
      },
    },
    created() {
      const id = this.$route.params.id;
      this.cargarDatosInforme(id);
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente */
  </style>
  