<template>
    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">
        Observar Jugador
      </v-card-title>
  
      <v-card-text>
        <jugador-form ref="JugadorForm" :initialData="jugadorData" :disableFields="true"></jugador-form>
      </v-card-text>
  
      <v-card-actions>
        <v-btn @click="redirigirAActualizar" color="primary">
          Actualizar
        </v-btn>
        <v-btn @click="redirigirAJugadores" color="primary">
          Volver
        </v-btn>
      </v-card-actions>
      <v-alert
      v-if="alertMessage"
      dense
      
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
    </v-card>
  </template>
  
  <script>
  import JugadorForm from '../../components/JugadorComponents/JugadorForm.vue';
  
  export default {
    components: {
        JugadorForm,
    },
    data() {
      return {
        alertMessage: null,
      alertType: null,
        jugadorData: null,
      };
    },
    methods: {
      async cargarDatosJugador(id) {
        
        try {
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
          const response = await fetch(`${API_BASE_URL}/jugadores/${id}`);
          if (!response.ok) {
            throw new Error('Error al obtener los datos del jugador');
          }
          const jugadorData = await response.json();
          this.jugadorData = jugadorData;
        } catch (error) {
          console.error('Error al cargar los datos del jugador:', error);
          this.setAlert('Error al cargar los datos del jugador, pulse en volver e intentelo de nuevo.', 'error');
        }
      },
      redirigirAActualizar() {
        // Redirige a la página de actualizar con el mismo ID de jugador
        this.$router.push({ name: 'ModificarJugador', params: { id: this.jugadorData.id } });
      },
      redirigirAJugadores() {
        // Redirige a la página de jugadors
        this.$router.push({ name: 'Plantilla'});
      },setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
    },
    created() {
      // Obtén el ID del jugador de los parámetros de la ruta
      const jugadorId = this.$route.params.id;
      // Carga los datos del jugador al iniciar la página
      this.cargarDatosJugador(jugadorId);
    },
    
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  