<template>
    <div>
        <!-- Chip encima de la tarjeta -->
        <v-row class="mb-4">
            <v-col cols="12" class="text-center">
                <v-chip color="indigo darken-1" class="white--text" large>
                    <v-icon left>mdi-calendar</v-icon>
                   {{'Última Jornada: ' + ultJornada}}
                </v-chip>
            </v-col>
        </v-row>
        <!-- Primera fila con 2 columnas para listas de sanciones -->
        <v-row class="mb-4">
            <!-- Fila 1 Columna 1: 1 Amarilla -->
            <v-col cols="6">
                <v-row>
                    <v-col cols="6">
                        <v-card class="pa-4 mb-4" outlined elevation="5">
                            <v-card-text>
                                <div class="text-center">
                                    <div class="text-h5 mb-2">1 Amarilla</div>
                                    <v-list>
                                        <v-list-item v-for="jugador in jugadoresAmarillas(1)" :key="jugador.jugador">
                                            <v-list-item-icon>
                                                <v-icon color="grey lighten-1">fas fa-user</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-body-2">{{ jugador.jugador
                                                    }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Columna 2 fila 1: 2 Amarillas -->
                    <v-col cols="6">
                        <v-card class="pa-4 mb-4" outlined elevation="5">
                            <v-card-text>
                                <div class="text-center">
                                    <div class="text-h5 mb-2">2 Amarillas</div>
                                    <v-list>
                                        <v-list-item v-for="jugador in jugadoresAmarillas(2)" :key="jugador.jugador">
                                            <v-list-item-icon>
                                                <v-icon color="yellow">fas fa-user</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-body-2">{{ jugador.jugador
                                                    }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row><v-row>
                    <!-- Columna 1 fila 2: 3 Amarillas -->
                    <v-col cols="6">
                        <v-card class="pa-4 mb-4" outlined elevation="5">
                            <v-card-text>
                                <div class="text-center">
                                    <div class="text-h5 mb-2">3 Amarillas</div>
                                    <v-list>
                                        <v-list-item v-for="jugador in jugadoresAmarillas(3)" :key="jugador.jugador">
                                            <v-list-item-icon>
                                                <v-icon color="orange">fas fa-user</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-body-2">{{ jugador.jugador
                                                    }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Columna 2 fila 2: 4 Amarillas -->
                    <v-col cols="6">
                        <v-card class="pa-4 mb-4" outlined elevation="5">
                            <v-card-text>
                                <div class="text-center">
                                    <div class="text-h5 mb-2">4 Amarillas</div>
                                    <v-list>
                                        <v-list-item v-for="jugador in jugadoresAmarillas(4)" :key="jugador.jugador">
                                            <v-list-item-icon>
                                                <v-icon color="red">fas fa-user</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-body-2">{{ jugador.jugador
                                                    }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <v-card class="pa-4 mb-4" outlined elevation="5" style="max-width: 600px;">
                    <v-card-text>
                        <div class="text-center">
                            <div class="text-h5 mb-2">Tabla de Sanciones</div>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Jugador</th>
                                            <th class="text-center">Amarillas</th>
                                            <th class="text-center">Ciclos</th>
                                            <th class="text-center">Rojas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(jugador, index) in sanciones" :key="index">
                                            <td class="text-left">{{ jugador.jugador }}</td>
                                            <td class="text-center">{{ jugador.amarillas }}</td>
                                            <td class="text-center">{{ jugador.ciclos }}</td>
                                            <td class="text-center">{{ jugador.rojas }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sanciones: [],
            ultJornada: ''
        };
    },
    async created() {
         //await this.fetchTarjetas();
    },
    async mounted() {
        await this.fetchTarjetas();
        await this.obtenerUltimaJornada();
    },
    methods: {

        async obtenerUltimaJornada() {
            try {
                const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

                const usuarioId = sessionStorage.getItem('userId');
                // Obtener el código de usuario
                const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
                if (!responseCodigo.ok) {
                    throw new Error('No se pudo obtener el código de usuario');
                }
                const codigoUsuario = await responseCodigo.text();
                
                // Realiza la solicitud al endpoint UltJornada
                const response = await fetch(`${API_BASE_URL}/partidos/UltJornada/${codigoUsuario}`);

                // Comprueba si la respuesta no es correcta
                if (!response.ok) {
                    throw new Error('No se pudo obtener la última jornada');
                }

                // Obtiene el texto de la respuesta
                this.ultJornada = await response.text();

            } catch (error) {
                this.ultJornada = 'error';
                console.error('Error al obtener la última jornada:', error);
                // Manejo de errores, como mostrar un mensaje al usuario
            }
        },

        jugadoresAmarillas(numAmarillas) {
            
            // Filtrar jugadores por número de amarillas ajustado al ciclo de 5 tarjetas
            return this.sanciones.filter(jugador => {
                const amarillas = jugador.amarillas;
                if (amarillas <= 4) {
                    return amarillas === numAmarillas;
                } else {
                    // Calcular el número de amarillas restantes después de completar ciclos de 5 tarjetas
                    const resto = amarillas % 5;
                    return resto === numAmarillas && resto !== 0;
                }
            });
        },

        async fetchTarjetas() {
            try {
                const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

                const usuarioId = sessionStorage.getItem('userId');
                // Obtener el código de usuario
                const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
                if (!responseCodigo.ok) {
                    throw new Error('No se pudo obtener el código de usuario');
                }
                const codigoUsuario = await responseCodigo.text();

                // Obtener todas las tarjetas
                const responseTarjetas = await fetch(`${API_BASE_URL}/tarjetas`);
                if (!responseTarjetas.ok) {
                    throw new Error('Error fetching data');
                }
                const data = await responseTarjetas.json();

                // Obtener la lista de jugadores
                const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
                if (!responseJugadores.ok) {
                    throw new Error('Error fetching players data');
                }
                const jugadores = await responseJugadores.json();
                this.jugadores = jugadores;

                // Crear un mapa de idJugador a apodo
                const jugadorMap = {};
                for (const jugador of jugadores) {
                    jugadorMap[jugador.id] = jugador.apodo;
                }

                // Filtrar las tarjetas del usuario
                const tarjetasUsuario = data.filter(tarjeta => tarjeta.codigo === codigoUsuario);

                // Procesar las tarjetas para obtener las sanciones
                const sanciones = {};

                for (const tarjeta of tarjetasUsuario) {
                    const { idJugador, jornada, color } = tarjeta;
                    if (!sanciones[idJugador]) {
                        sanciones[idJugador] = { jugador: jugadorMap[idJugador] || idJugador, amarillas: 0, ciclos: 0, rojas: 0 };
                    }

                    if (color === '1 AMARILLA') {
                        // Comprobar si existe una '2 AMARILLA' para el mismo jugador y jornada
                        const tieneSegundaAmarilla = tarjetasUsuario.some(t =>
                            t.idJugador === idJugador && t.jornada === jornada && t.color === '2 AMARILLA'
                        );

                        if (tieneSegundaAmarilla) {
                            sanciones[idJugador].rojas++;
                        } else {
                            sanciones[idJugador].amarillas++;
                        }
                    } else if (color === 'ROJA') {
                        sanciones[idJugador].rojas++;
                    }
                }

                // Calcular los ciclos
                for (const idJugador in sanciones) {
                    const jugadorSanciones = sanciones[idJugador];
                    jugadorSanciones.ciclos = Math.floor(jugadorSanciones.amarillas / 5);
                }

                
                // Convertir el objeto sanciones a un array
                this.sanciones = Object.values(sanciones);

            } catch (error) {
                console.error('Error al obtener las tarjetas:', error);
            }
        },

    }
};
</script>

<style scoped>
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}
</style>