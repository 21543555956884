<template>
  <v-card class="mx-auto" width="100%">
    <v-alert v-if="alertMessage"  dense :type="alertType">
      {{ alertMessage }}
    </v-alert>

    <v-card-text>
      <v-spacer class="my-4"></v-spacer> <!-- Añadir espacio entre el título y el formulario -->

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="formData.nombre" label="Nombre del Informe" :rules="nombreRules"
            :disabled="disableFields" outlined></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-select v-model="formData.tipo"
            :items="['Jugador propio', 'Equipo propio', 'Jugador rival', 'Equipo rival', 'Otro']" label="Tipo" outlined
            clearable :disabled="disableFields"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="9" md="3">
          <v-select v-model="temporadaInicio" :items="años" label="Inicio de Temporada" outlined clearable
            @change="actualizarTemporada" :disabled="disableFields"></v-select>
        </v-col>

        <!-- Columna para la barra "/" centrada -->
        <v-col cols="12" md="1" class="text-center">
          <span style="font-size: 48px; line-height: 56px;">/</span>
        </v-col>

        <v-col cols="9" md="3">
          <v-select v-model="temporadaFin" :items="años" label="Fin de Temporada" outlined clearable
            @change="actualizarTemporada" :disabled="disableFields"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-select v-model="formData.jornada" :items="jornadas" label="Jornada" outlined clearable
            :disabled="disableFields"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="formData.descripcion" label="Descripción del Informe" :rules="descripcionRules" outlined
            :disabled="disableFields"></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: null,
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: String,
      default: 'Informe',
    },
  },
  data() {
    return {
      formData: {
        nombre: '',
        temporada: '',
        tipo: '',
        jornada: null,
        descripcion: '',
      },
      temporadaInicio: null,
      temporadaFin: null,
      años: this.generarAños(),
      jornadas: this.generarJornadas(),
      alertMessage: '',
      alertType: 'error',
      showRules: false,
    };
  },
  computed: {
    nombreRules() {
      return [(v) => (!!v || this.showRules) || 'El nombre es obligatorio'];
    },
    descripcionRules() {
      return [(v) => (!!v || this.showRules) || 'La descripción es obligatoria'];
    },
  },
  methods: {
    generarAños() {
      const años = [];
      for (let i = 2000; i <= 2100; i++) {
        años.push(i);
      }
      return años;
    },
    generarJornadas() {
      const jornadas = [];
      for (let i = 1; i <= 34; i++) {
        jornadas.push(i);
      }
      return jornadas;
    },
    actualizarTemporada() {
      if (this.temporadaInicio && this.temporadaFin) {
        this.formData.temporada = `${this.temporadaInicio}/${this.temporadaFin}`;
      }
    },
    activateRules() {
      this.showRules = true;
    },
    getFormData() {
      return { ...this.formData };
    },
    resetForm() {
      this.showRules = false;
      this.formData = {
        nombre: '',
        temporada: '',
        tipo: '',
        jornada: null,
        descripcion: '',
      };
      this.temporadaInicio = null;
      this.temporadaFin = null;
    },
  },
  watch: {
    initialData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.formData = {
            nombre: newData.nombre,
            temporada: newData.temporada,
            tipo: newData.tipo,
            jornada: newData.jornada,
            descripcion: newData.descripcion,
          };
          const temporadaPartes = newData.temporada ? newData.temporada.split('/') : [];
          this.temporadaInicio = temporadaPartes[0] ? parseInt(temporadaPartes[0]) : null;
          this.temporadaFin = temporadaPartes[1] ? parseInt(temporadaPartes[1]) : null;
        }
      },
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para el componente InformeForm */
</style>
