<template>

    <v-card class="mx-auto" max-width="2000">

        <v-alert v-if="true" dismissible type="info" closable="true" icon="mdi-alert" class="text-h6">
            <template v-slot:title>
                Aviso Importante
            </template>
            <span>
                Revisa bien el acta, los partidos registrados luego no se podrán editar ni eliminar.
                Un mal dato registrado puede alterar los datos y llevarte a conclusiones erroneas.
            </span>
        </v-alert>

        <v-alert v-if="alertMessage"  dense :type="alertType">
            {{ alertMessage }}
        </v-alert>

        <v-card-title class="headline">
            Nueva acta de partido FUTBOL 11
        </v-card-title>

        <v-card-text>
            Completa los detalles del partido para generar una nueva acta. Asegúrate de revisar toda la información
            antes de enviar.

            <v-spacer class="my-4"></v-spacer> <!-- Añadir espacio entre el texto y la primera fila -->

            <v-row><!-- Primera fila -->
                <v-col cols="12" md="2">
                    <v-select v-model="jornadaSeleccionada" :items="jornadas" label="JORNADA" outlined clearable
                        background-color="#E0E0E0" :error-messages="errorValidacionJornada ? 'Campo Obligatorio' : []">
                        <template v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="lugarPartido" :items="['Casa', 'Fuera']" label="LUGAR" outlined clearable
                        background-color="#E0E0E0" :error-messages="errorValidacionLugar ? 'Campo Obligatorio' : []">
                        <template v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select v-model="rivalPartidoSeleccionado" :items="rivales" item-text="nombre" item-value="id"
                        label="RIVAL" outlined background-color="#E0E0E0" clearable
                        :error-messages="errorValidacionRival ? 'Campo Obligatorio' : []">
                        <template v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="formacionEquipo" label="FORMACIÓN UTILIZADA" outlined
                        background-color="#E0E0E0"
                        :error-messages="errorValidacionFormacion ? 'Campo Obligatorio' : []">
                        <template v-slot:append><span class="required-asterisk">*</span></template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field v-model="resultadoPartido" label="RESULTADO" outlined background-color="#E0E0E0"
                        @blur="validarResultado" :error-messages="errorValidacionResultado ">
                        <template v-slot:append><span class="required-asterisk">*</span></template>
                    </v-text-field>
                </v-col>
            </v-row>

            <!-- //Comienzan las filas de los jugadores-->
            <v-row>
                <v-col cols="12" md="2">
                    <span>TITULARES</span>
                </v-col>
                <v-col cols="12" md="2">
                    <span></span>
                </v-col>
                <v-col cols="12" md="2">
                    <span>SUPLENTES/DESCARTES</span>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorUnoSeleccionado" :items="jugadores" item-text="apodo" item-value="id"
                        clearable label="TITULAR 1" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorUno ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorUnoPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaUno ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDoceSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="SUPLENTE 1" outlined background-color="#C8E6C9">
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDocePuntuacion" :items="puntuaciones" label="Nota" outlined clearable>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorDocePor" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="Entra por:" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorDoceMinuto" :items="minutos" clearable label="Minuto"
                        outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDosSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="TITULAR 2" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorDos ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDosPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaDos ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorTreceSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="SUPLENTE 2" outlined background-color="#C8E6C9">
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorTrecePuntuacion" :items="puntuaciones" clearable label="Nota" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorTrecePor" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="Entra por:" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorTreceMinuto" :items="minutos" clearable label="Minuto"
                        outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorTresSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="TITULAR 3" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorTres ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorTresPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaTres ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCatorceSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="SUPLENTE 3" outlined background-color="#C8E6C9">
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCatorcePuntuacion" :items="puntuaciones" clearable label="Nota" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorCatorcePor" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="Entra por:" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorCatorceMinuto" :items="minutos" clearable label="Minuto"
                        outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCuatroSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="TITULAR 4" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorCuatro ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCuatroPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaCuatro ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorQuinceSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="SUPLENTE 4" outlined background-color="#C8E6C9">
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorQuincePuntuacion" :items="puntuaciones" clearable label="Nota" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorQuincePor" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="Entra por:" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorQuinceMinuto" :items="minutos" clearable label="Minuto"
                        outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCincoSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="TITULAR 5" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorCinco ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorCincoPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaCinco ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDieciseisSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="SUPLENTE 5" outlined background-color="#C8E6C9">
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDieciseisPuntuacion" :items="puntuaciones" clearable label="Nota"
                        outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorDieciseisPor" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="Entra por:" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="cambioJugadorDieciseisMinuto" :items="minutos" clearable label="Minuto"
                        outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorSeisSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="TITULAR 6" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorSeis ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorSeisPuntuacion" :items="puntuaciones" clearable label="Nota" outlined
                        :error-messages="errorValidacionNotaSeis ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDiecisieteSeleccionado" :items="jugadores" clearable item-text="apodo"
                        item-value="id" label="DESCARTE 1" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaDiecisiete" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorSieteSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="TITULAR 7" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorSiete ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorSietePuntuacion" clearable :items="puntuaciones" label="Nota" outlined
                        :error-messages="errorValidacionNotaSiete ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDieciochoSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 2" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaDieciocho" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorOchoSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="TITULAR 8" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorOcho ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorOchoPuntuacion" clearable :items="puntuaciones" label="Nota" outlined
                        :error-messages="errorValidacionNotaOcho ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDiecinueveSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 3" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaDiecinueve" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorNueveSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="TITULAR 9" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorNueve ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorNuevePuntuacion" clearable :items="puntuaciones" label="Nota" outlined
                        :error-messages="errorValidacionNotaNueve ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeinteSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 4" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaVeinte" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDiezSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="TITULAR 10" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorDiez ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorDiezPuntuacion" clearable :items="puntuaciones" label="Nota" outlined
                        :error-messages="errorValidacionNotaDiez ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeintiUnoSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 5" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaVeintiUno" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorOnceSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="TITULAR 11" outlined background-color="#BBDEFB"
                        :error-messages="errorValidacionJugadorOnce ? 'Campo Obligatorio' : []"> <template
                            v-slot:append><span class="required-asterisk">*</span></template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorOncePuntuacion" clearable :items="puntuaciones" label="Nota" outlined
                        :error-messages="errorValidacionNotaOnce ? 'Campo Obligatorio' : []">
                        <template v-slot:append>
                            <span class="required-asterisk">*</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeintiDosSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 6" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select v-model="motivoDesconvocatoriaVeintiDos" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeintitresSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 7" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="motivoDesconvocatoriaVeintitres" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeinticuatroSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 8" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="motivoDesconvocatoriaVeinticuatro" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="v-row.mb-small">
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeinticincoSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 9" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="motivoDesconvocatoriaVeinticinco" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select v-model="jugadorVeintiseisSeleccionado" clearable :items="jugadores" item-text="apodo"
                        item-value="id" label="DESCARTE 10" outlined background-color="#FFE0B2">
                    </v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="motivoDesconvocatoriaVeintiseis" clearable :items="motivosDesconvocatoria"
                        label="Motivo Desconvocatoria" outlined>
                    </v-select>
                </v-col>
            </v-row>



            <v-row>
                <!-- Tabla de GOLES -->
                <v-col cols="12" md="6">
                    <v-card color="green lighten-2">
                        <v-card-title class="headline">
                            GOLES A FAVOR
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>GOLEADOR</th>
                                            <th>ASISTENTE</th>
                                            <th>MN</th>
                                            <th>DESCRIPCIÓN</th>
                                            <th>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Iterar sobre los goles y mostrar cada uno en la tabla -->
                                        <tr v-for="(gol, index) in goles" :key="index">
                                            <td>{{ gol.goleador.nombre }}</td>
                                            <td>{{ gol.asistente.nombre }}</td>
                                            <td>{{ gol.minuto }}</td>
                                            <td>{{ gol.descripcion }}</td>
                                            <td>
                                                <!-- Icono de papelera para borrar la fila -->
                                                <v-icon @click="eliminarGol(index)" color="red" class="cursor-pointer">
                                                    mdi-delete
                                                </v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- Tabla de TARJETAS -->
                <v-col cols="12" md="6">
                    <v-card color="yellow lighten-2">
                        <v-card-title class="headline">
                            TARJETAS RECIBIDAS
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>TARJETA</th>
                                            <th>JUGADOR</th>
                                            <th>MN</th>
                                            <th>ACCIONES</th> <!-- Nueva columna para acciones -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Iterar sobre las tarjetas y mostrar cada una en la tabla -->
                                        <tr v-for="(tarjeta, index) in tarjetas" :key="index">
                                            <td>{{ tarjeta.color }}</td>
                                            <td>{{ tarjeta.jugador.nombre }}</td>
                                            <td>{{ tarjeta.minuto }}</td>
                                            <td>
                                                <!-- Icono de papelera para borrar la fila -->
                                                <v-icon @click="eliminarTarjeta(index)" color="red"
                                                    class="cursor-pointer">
                                                    mdi-delete
                                                </v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <!-- Inputs para añadir goles -->
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="3">
                            <v-select v-model="nuevoGol.goleador" clearable :items="goleadores" item-text="apodo"
                                item-value="id" label="GOLEADOR"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-select v-model="nuevoGol.asistente" clearable :items="asistentes" item-text="apodo"
                                item-value="id" label="ASISTENTE"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field v-model="nuevoGol.minuto" label="MN"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-select v-model="nuevoGol.descripcion" clearable :items="descripcionesGol"
                                label="DESCRIPCIÓN GOL">
                                <template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="2">
                            <v-btn @click="agregarGol" color="green lighten-2">Añadir GOL</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Inputs para añadir tarjetas -->
                <v-col cols="12" md="6">
                    <v-row justify="end">

                        <v-col cols="4">
                            <v-select v-model="nuevaTarjeta.color" clearable
                                :items="['1 AMARILLA', '2 AMARILLA', 'ROJA']" label="TARJETA"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-select v-model="nuevaTarjeta.jugador" clearable :items="jugadores" item-text="apodo"
                                item-value="id" label="JUGADOR"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field v-model="nuevaTarjeta.minuto" label="MN"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="end">
                        <v-col cols="12" md="3">
                            <v-btn @click="agregarTarjeta" color="yellow lighten-2">Añadir</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <!-- Inputs para añadir goles -->
                <v-col cols="12" md="6">
                    <v-alert v-if="errorMessageGoles"  type="error">
                        {{ errorMessageGoles }}
                    </v-alert>
                </v-col>
                <!-- Inputs para añadir tarjetas -->
                <v-col cols="12" md="6">
                    <v-alert v-if="errorMessageTarjetas"  type="error">
                        {{ errorMessageTarjetas }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <!-- Tabla de GOLES -->
                <v-col cols="12" md="6">
                    <v-card color="red lighten-2">
                        <v-card-title class="headline">
                            GOLES EN CONTRA
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>MN</th>
                                            <th>DESCRIPCIÓN</th>
                                            <th>PORTERO</th>
                                            <th>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Iterar sobre los goles y mostrar cada uno en la tabla -->
                                        <tr v-for="(golR, index) in golesRival" :key="index">
                                            <td>{{ golR.minuto }}</td>
                                            <td>{{ golR.descripcion }}</td>
                                            <td>{{ golR.portero }}</td>
                                            <td>
                                                <!-- Icono de papelera para borrar la fila -->
                                                <v-icon @click="eliminarGolRival(index)" color="red"
                                                    class="cursor-pointer">
                                                    mdi-delete
                                                </v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <!-- Inputs para añadir goles del equipo rival -->
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="nuevoGolRival.minuto" label="MN"><template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-select v-model="nuevoGolRival.descripcion" clearable :items="descripcionesGol"
                                label="DESCRIPCIÓN GOL">
                                <template v-slot:append>
                                    <span class="required-asterisk">*</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="5" >
                            <v-select v-model="nuevoGolRival.portero" clearable :items="jugadores" item-text="apodo"
                                item-value="apodo" label="PORTERO"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="2">
                            <v-btn @click="agregarGolRival" color="red lighten-2">Añadir GOL RIVAL</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <!-- Inputs para añadir goles del rival-->
                <v-col cols="12" md="6">
                    <v-alert v-if="errorMessageGolesRival"  type="error">
                        {{ errorMessageGolesRival }}
                    </v-alert>
                </v-col>
            </v-row>

            <v-alert v-if="alertMessage"  dense :type="alertType">
                {{ alertMessage }}
            </v-alert>

        </v-card-text>

        <v-card-actions class="d-flex justify-center">
            <v-btn @click="crearActaPartido" color="grey lighten-2" class="ma-10" 
            large
            :disabled="estaCreandosePartido">
                Crear Acta Partido F11
            </v-btn>
        </v-card-actions>

        <!-- Diálogo -->
        <v-dialog v-model="dialogRevisionActa" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="headline">Detalles del Partido</span>
                </v-card-title>

                <v-card-text>
                    <!-- Mostrar valores del partido -->
                    <div>
                        <strong>Jornada:</strong> {{ jornadaSeleccionada }} <br />
                        <strong>Lugar del Partido:</strong> {{ lugarPartido }} <br />
                        <strong>Formación del Equipo:</strong> {{ formacionEquipo }} <br />
                        <strong>Rival del Partido:</strong> {{ obtenerNombreRival(rivalPartidoSeleccionado) }} <br />
                        <strong>Resultado del Partido:</strong> {{ resultadoPartido }} <br />
                    </div>

                    <!-- Titulares -->
                    <div class="mt-4">
                        <strong>TITULARES:</strong>
                        <div><strong>Jugador 1:</strong> {{ obtenerApodoJugador(jugadorUnoSeleccionado) }}</div>
                        <div><strong>Jugador 2:</strong> {{ obtenerApodoJugador(jugadorDosSeleccionado) }}</div>
                        <div><strong>Jugador 3:</strong> {{ obtenerApodoJugador(jugadorTresSeleccionado) }}</div>
                        <div><strong>Jugador 4:</strong> {{ obtenerApodoJugador(jugadorCuatroSeleccionado) }}</div>
                        <div><strong>Jugador 5:</strong> {{ obtenerApodoJugador(jugadorCincoSeleccionado) }}</div>
                        <div><strong>Jugador 6:</strong> {{ obtenerApodoJugador(jugadorSeisSeleccionado) }}</div>
                        <div><strong>Jugador 7:</strong> {{ obtenerApodoJugador(jugadorSieteSeleccionado) }}</div>
                        <div><strong>Jugador 8:</strong> {{ obtenerApodoJugador(jugadorOchoSeleccionado) }}</div>
                        <div><strong>Jugador 9:</strong> {{ obtenerApodoJugador(jugadorNueveSeleccionado) }}</div>
                        <div><strong>Jugador 10:</strong> {{ obtenerApodoJugador(jugadorDiezSeleccionado) }}</div>
                        <div><strong>Jugador 11:</strong> {{ obtenerApodoJugador(jugadorOnceSeleccionado) }}</div>
                    </div>

                    <!-- Suplentes (Jugador 12 al 16) -->
                    <div class="mt-4">
                        <strong>SUPLENTES:</strong>
                        <div v-if="jugadorDoceSeleccionado"><strong>Jugador 12:</strong> {{obtenerApodoJugador(jugadorDoceSeleccionado) }}</div>
                        <div v-if="jugadorTreceSeleccionado"><strong>Jugador 13:</strong> {{obtenerApodoJugador(jugadorTreceSeleccionado) }}</div>
                        <div v-if="jugadorCatorceSeleccionado"><strong>Jugador 14:</strong> {{obtenerApodoJugador(jugadorCatorceSeleccionado) }}</div>
                        <div v-if="jugadorQuinceSeleccionado"><strong>Jugador 15:</strong> {{obtenerApodoJugador(jugadorQuinceSeleccionado) }}</div>
                        <div v-if="jugadorDieciseisSeleccionado"><strong>Jugador 16:</strong> {{obtenerApodoJugador(jugadorDieciseisSeleccionado) }}</div>
                    </div>

                    <!-- Descartes (Jugador 17 al 24) -->
                    <div class="mt-4">
                        <strong>DESCARTES:</strong>
                        <div v-if="jugadorDiecisieteSeleccionado"><strong>Jugador 17:</strong> {{obtenerApodoJugador(jugadorDiecisieteSeleccionado) }}</div>
                        <div v-if="jugadorDieciochoSeleccionado"><strong>Jugador 18:</strong> {{obtenerApodoJugador(jugadorDieciochoSeleccionado) }}</div>
                        <div v-if="jugadorDiecinueveSeleccionado"><strong>Jugador 19:</strong> {{obtenerApodoJugador(jugadorDiecinueveSeleccionado) }}</div>
                        <div v-if="jugadorVeinteSeleccionado"><strong>Jugador 20:</strong> {{obtenerApodoJugador(jugadorVeinteSeleccionado) }}</div>
                        <div v-if="jugadorVeintiUnoSeleccionado"><strong>Jugador 21:</strong> {{obtenerApodoJugador(jugadorVeintiUnoSeleccionado) }}</div>
                        <div v-if="jugadorVeintiDosSeleccionado"><strong>Jugador 22:</strong> {{obtenerApodoJugador(jugadorVeintiDosSeleccionado) }}</div>
                        <div v-if="jugadorVeintitresSeleccionado"><strong>Jugador 23:</strong> {{obtenerApodoJugador(jugadorVeintitresSeleccionado) }}</div>
                        <div v-if="jugadorVeinticuatroSeleccionado"><strong>Jugador 24:</strong> {{obtenerApodoJugador(jugadorVeinticuatroSeleccionado) }}</div>
                        <div v-if="jugadorVeinticincoSeleccionado"><strong>Jugador 25:</strong> {{obtenerApodoJugador(jugadorVeinticincoSeleccionado) }}</div>
                        <div v-if="jugadorVeintiseisSeleccionado"><strong>Jugador 26:</strong> {{obtenerApodoJugador(jugadorVeintiseisSeleccionado) }}</div>
                    </div>

                    <!-- CAMBIOS  -->
                    <div class="mt-4">
                        <strong>SUSTITUCIONES:</strong>
                        <div v-if="jugadorDoceSeleccionado"><strong>Entra:</strong> {{obtenerApodoJugador(jugadorDoceSeleccionado) }}<strong> por:</strong>{{obtenerApodoJugador(cambioJugadorDocePor) }}<strong> Mn:</strong>{{cambioJugadorDoceMinuto }}</div>
                        <div v-if="jugadorTreceSeleccionado"><strong>Entra:</strong> {{obtenerApodoJugador(jugadorTreceSeleccionado) }}<strong> por:</strong>{{obtenerApodoJugador(cambioJugadorTrecePor) }}<strong> Mn:</strong>{{cambioJugadorTreceMinuto }}</div>
                        <div v-if="jugadorCatorceSeleccionado"><strong>Entra:</strong> {{obtenerApodoJugador(jugadorCatorceSeleccionado) }}<strong> por:</strong>{{obtenerApodoJugador(cambioJugadorCatorcePor) }}<strong> Mn:</strong>{{cambioJugadorCatorceMinuto }}</div>
                        <div v-if="jugadorQuinceSeleccionado"><strong>Entra:</strong> {{obtenerApodoJugador(jugadorQuinceSeleccionado) }}<strong> por:</strong>{{obtenerApodoJugador(cambioJugadorQuincePor) }}<strong> Mn:</strong>{{cambioJugadorQuinceMinuto }}</div>
                        <div v-if="jugadorDieciseisSeleccionado"><strong>Entra:</strong> {{obtenerApodoJugador(jugadorDieciseisSeleccionado) }}<strong> por:</strong>{{obtenerApodoJugador(cambioJugadorDieciseisPor) }}<strong> Mn:</strong>{{cambioJugadorDieciseisMinuto }}</div>
                    </div>

                    <!-- GOLES  -->
                    <div class="mt-4">
                        <strong>Goles a favor:</strong>
                        <!-- Recorre el array de goles y muestra la información de cada gol -->
                        <div v-for="(gol, index) in goles" :key="index" class="mt-2">
                        <div><strong>Gol {{ index + 1 }}-> marca:</strong> {{ obtenerApodoJugador(gol.goleador.id) }}<strong>  en el minuto:</strong> {{ gol.minuto }}<strong>, por asistencia de:</strong> {{ obtenerApodoJugador(gol.asistente.id) }}</div>
                        <div><strong>Descripción del gol:</strong> {{ gol.descripcion }}</div>
                        </div>
                    </div>

                     <!-- GOLES en contra  -->
                     <div class="mt-4">
                        <strong>Goles en contra:</strong>
                        <!-- Recorre el array de goles y muestra la información de cada gol -->
                        <div v-for="(gol, index) in golesRival" :key="index" class="mt-2">
                        <div><strong>Gol {{ index + 1 }}-> en el minuto:</strong> {{ gol.minuto }}<strong>, descripción del gol: </strong> {{ gol.descripcion}}</div>
                        </div>
                    </div>

                    <!-- TARJETAS -->
                    <div class="mt-4">
                        <strong>Tarjetas:</strong>
                        <!-- Recorre el array de goles y muestra la información de cada gol -->
                        <div v-for="(tarjeta, index) in tarjetas" :key="index" class="mt-2">
                        <div><strong>Minuto:</strong> {{ tarjeta.minuto }}<strong>, tarjeta: </strong> {{ tarjeta.color}}<strong> a  </strong> {{ obtenerApodoJugador(tarjeta.jugador.id) }}</div>
                        </div>
                    </div>


                </v-card-text>

                <v-card-actions>
                    <v-btn @click="confirmacionActa" color="green lighten-2" class="" large>Confirmar Acta Partido F11</v-btn>
                    <v-btn color="red lighten-2" large @click="dialogRevisionActa = false">Volver</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
export default {
    data() {
        return {
            // Controla si el diálogo está abierto o cerrado
            dialogRevisionActa: false,
            estaCreandosePartido: false,
            alertMessage: '',  // Mensaje de alerta vacío inicialmente
            alertType: 'success', // Tipo de alerta: puede ser 'success', 'info', 'warning' o 'error'

            jornadaSeleccionada: '',  // Variable para almacenar la jornada seleccionada
            jornadas: Array.from({ length: 34 }, (v, k) => k + 1),  // Array con los números de jornada del 1 al 34
            minutos: Array.from({ length: 90 }, (v, k) => k + 1),  // Array con los minutos de un partido

            // Definición del array de opciones
            motivosDesconvocatoria: [
                'Decisión Técnica',
                'Falta a entrenar sin justificar',
                'Falta de disciplina',
                'Rotación',
                'Por calendario',
                'Falta de disponibilidad',
                'Prevención lesión',
                'Lesión'
            ],

            // Definición del array de opciones
            puntuaciones: [
                'MVP',
                'Sobresaliente',
                'Bien',
                'Flojo',
                'Muy flojo',
                'NP'
            ],

            descripcionesGol: [
            'Penalti',
            'Libre-directo',
            'Balón Parado',
            'Centro lateral',
            'Tras presión',
            'Contrataque',
            'Ata-posicional',
            'Mano-mano',
            'Disparo lejano',
            'Pase atras'
            ],

            lugarPartido: '',  // Variable para almacenar el lugar del partido seleccionado
            formacionEquipo: '',

            rivalPartidoSeleccionado: '',  // Variable para almacenar el rival seleccionado
            rivales: [],  // Array para almacenar los rivales cargados desde la API
            jugadores: [],
            goleadores: [],
            asistentes: [],

            resultadoPartido: '',  // Variable para almacenar el resultado del partido

            jugadorUnoSeleccionado: '',
            jugadorDosSeleccionado: '',
            jugadorTresSeleccionado: '',
            jugadorCuatroSeleccionado: '',
            jugadorCincoSeleccionado: '',
            jugadorSeisSeleccionado: '',
            jugadorSieteSeleccionado: '',
            jugadorOchoSeleccionado: '',
            jugadorNueveSeleccionado: '',
            jugadorDiezSeleccionado: '',
            jugadorOnceSeleccionado: '',
            jugadorDoceSeleccionado: '',
            jugadorTreceSeleccionado: '',
            jugadorCatorceSeleccionado: '',
            jugadorQuinceSeleccionado: '',
            jugadorDieciseisSeleccionado: '',
            jugadorDiecisieteSeleccionado: '',
            jugadorDieciochoSeleccionado: '',
            jugadorDiecinueveSeleccionado: '',
            jugadorVeinteSeleccionado: '',
            jugadorVeintiUnoSeleccionado: '',
            jugadorVeintiDosSeleccionado: '',
            jugadorVeintitresSeleccionado: '',
            jugadorVeinticuatroSeleccionado: '',
            jugadorVeinticincoSeleccionado: '',
            jugadorVeintiseisSeleccionado: '',

            jugadorUnoPuntuacion: '',
            jugadorDosPuntuacion: '',
            jugadorTresPuntuacion: '',
            jugadorCuatroPuntuacion: '',
            jugadorCincoPuntuacion: '',
            jugadorSeisPuntuacion: '',
            jugadorSietePuntuacion: '',
            jugadorOchoPuntuacion: '',
            jugadorNuevePuntuacion: '',
            jugadorDiezPuntuacion: '',
            jugadorOncePuntuacion: '',
            jugadorDocePuntuacion: '',
            jugadorTrecePuntuacion: '',
            jugadorCatorcePuntuacion: '',
            jugadorQuincePuntuacion: '',
            jugadorDieciseisPuntuacion: '',

            cambioJugadorDocePor: '',
            cambioJugadorTrecePor: '',
            cambioJugadorCatorcePor: '',
            cambioJugadorQuincePor: '',
            cambioJugadorDieciseisPor: '',

            cambioJugadorDoceMinuto: 0,
            cambioJugadorTreceMinuto: 0,
            cambioJugadorCatorceMinuto: 0,
            cambioJugadorQuinceMinuto: 0,
            cambioJugadorDieciseisMinuto: 0,

            motivoDesconvocatoriaDiecisiete: '',
            motivoDesconvocatoriaDieciocho: '',
            motivoDesconvocatoriaDiecinueve: '',
            motivoDesconvocatoriaVeinte: '',
            motivoDesconvocatoriaVeintiUno: '',
            motivoDesconvocatoriaVeintiDos: '',
            motivoDesconvocatoriaVeintitres: '',
            motivoDesconvocatoriaVeinticuatro: '',
            motivoDesconvocatoriaVeinticinco: '',
            motivoDesconvocatoriaVeintiseis: '',

            nuevoGol: {
                goleador: '',
                asistente: '',
                minuto: '',
                descripcion: ''
            },
            goles: [], // Array para almacenar los goles
            errorMessageGoles: '', // Mensaje de error

            nuevoGolRival: {
                minuto: '',
                descripcion: '',
                PORTERO:''
            },
            golesRival:[],
            errorMessageGolesRival: '', // Mensaje de error

            nuevaTarjeta: {
                color: '',
                jugador: '',
                minuto: ''
            },
            tarjetas: [], // Array para almacenar los goles
            errorMessageTarjetas: '', // Mensaje de error

            //Validaciones
            errorValidacionResultado: false,
            errorValidacionRival: false,
            errorValidacionLugar: false,
            errorValidacionJornada: false,
            errorValidacionFormacion: false,

            errorValidacionJugadorUno: false,
            errorValidacionJugadorDos: false,
            errorValidacionJugadorTres: false,
            errorValidacionJugadorCuatro: false,
            errorValidacionJugadorCinco: false,
            errorValidacionJugadorSeis: false,
            errorValidacionJugadorSiete: false,
            errorValidacionJugadorOcho: false,
            errorValidacionJugadorNueve: false,
            errorValidacionJugadorDiez: false,
            errorValidacionJugadorOnce: false,

            errorValidacionNotaUno: false,
            errorValidacionNotaDos: false,
            errorValidacionNotaTres: false,
            errorValidacionNotaCuatro: false,
            errorValidacionNotaCinco: false,
            errorValidacionNotaSeis: false,
            errorValidacionNotaSiete: false,
            errorValidacionNotaOcho: false,
            errorValidacionNotaNueve: false,
            errorValidacionNotaDiez: false,
            errorValidacionNotaOnce: false,
            errorValidacionNotaDoce: false,
            errorValidacionNotaTrece: false,
            errorValidacionNotaCatorce: false,
            errorValidacionNotaQuince: false,
            errorValidacionNotaDieciseis: false,

        };
    },
    methods: {

        obtenerNombreRival(id) {
            // Busca el rival por su ID en el array de rivales
            const rival = this.rivales.find(r => r.id === id);

            // Si encuentra un rival, retorna su nombre, sino retorna un string vacío o un mensaje
            return rival ? rival.nombre : 'Rival no encontrado';
        },

        eliminarGol(index) {
            this.goles.splice(index, 1); // Elimina gol a favor  en la posición 'index'
        },

        eliminarGolRival(index) {
            this.golesRival.splice(index, 1); // Elimina gol en contra en la posición 'index'
        },


        eliminarTarjeta(index) {
            this.tarjetas.splice(index, 1); // Elimina la tarjeta en la posición 'index'
        },

        validarResultado() {
            const formatoResultado = /^\d{1,2}-\d{1,2}$/;
            if (!this.resultadoPartido) {
                this.errorValidacionResultado = 'Campo Obligatorio';
            } else if (!formatoResultado.test(this.resultadoPartido)) {
                this.errorValidacionResultado = 'El formato debe ser X-X, donde X es un número de 1 o 2 dígitos';
            } else {
                this.errorValidacionResultado = '';
            }
        },

        obtenerApodoJugador(idJugador) {
            const jugador = this.jugadores.find(jugador => jugador.id === idJugador);
            return jugador ? jugador.apodo : '';
        },

        async crearActaPartido() {
            
            if (this.jornadaSeleccionada) {
                const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
                const usuarioId = sessionStorage.getItem('userId');

                // Obtener el código del usuario
                const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
                if (!responseCodigo.ok) {
                    throw new Error('No se pudo obtener el código de usuario');
                }
                const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código es un texto simple

                // Hacer la solicitud para obtener las jornadas por el código de partido
                const responseJornadas = await fetch(`${API_BASE_URL}/partidos/jornadas/${codigoUsuario}`);
                if (!responseJornadas.ok) {
                    throw new Error('No se pudo obtener las jornadas para el código proporcionado');
                }

                // Obtener las jornadas como un array de strings
                const jornadas = await responseJornadas.json();

                // 3. Convertir los valores del array jornadas a números
                const jornadasNumeradas = jornadas.map(jornada => parseInt(jornada, 10));

                // 4. Validar que this.jornadaSeleccionada no esté en el array jornadasNumeradas
                if (jornadasNumeradas.includes(this.jornadaSeleccionada)) {
                    this.alertMessage = `Jornada ${this.jornadaSeleccionada} ya registrada, selecciona una nueva.`;
                    this.alertType = 'error';
                    this.estaCreandosePartido = false;
                    return; // Detener la ejecución si hay jornadas duplicadas
                }
            }

            // Crear un array con todas las puntuaciones de los jugadores
            const puntuacionesJugadores = [
                this.jugadorUnoPuntuacion,
                this.jugadorDosPuntuacion,
                this.jugadorTresPuntuacion,
                this.jugadorCuatroPuntuacion,
                this.jugadorCincoPuntuacion,
                this.jugadorSeisPuntuacion,
                this.jugadorSietePuntuacion,
                this.jugadorOchoPuntuacion,
                this.jugadorNuevePuntuacion,
                this.jugadorDiezPuntuacion,
                this.jugadorOncePuntuacion,
                this.jugadorDocePuntuacion,
                this.jugadorTrecePuntuacion,
                this.jugadorCatorcePuntuacion,
                this.jugadorQuincePuntuacion,
                this.jugadorDieciseisPuntuacion
            ];

            // Contar cuántos jugadores tienen la puntuación MVP
            const numeroDeMVP = puntuacionesJugadores.filter(puntuacion => puntuacion === 'MVP').length;

            // Validar si hay más de un MVP o ninguno
            if (numeroDeMVP > 1) {
                this.alertMessage = 'Solo un jugador puede tener la puntuación MVP.';
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                return; 
            } else if (numeroDeMVP === 0) {
                this.alertMessage = 'Debe haber al menos un jugador con la puntuación MVP.';
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                return; 
            }

            const jugadoresSeleccionados = [
                this.jugadorUnoSeleccionado,
                this.jugadorDosSeleccionado,
                this.jugadorTresSeleccionado,
                this.jugadorCuatroSeleccionado,
                this.jugadorCincoSeleccionado,
                this.jugadorSeisSeleccionado,
                this.jugadorSieteSeleccionado,
                this.jugadorOchoSeleccionado,
                this.jugadorNueveSeleccionado,
                this.jugadorDiezSeleccionado,
                this.jugadorOnceSeleccionado,
                this.jugadorDoceSeleccionado,
                this.jugadorTreceSeleccionado,
                this.jugadorCatorceSeleccionado,
                this.jugadorQuinceSeleccionado,
                this.jugadorDieciseisSeleccionado,
                this.jugadorDiecisieteSeleccionado,
                this.jugadorDieciochoSeleccionado,
                this.jugadorDiecinueveSeleccionado,
                this.jugadorVeinteSeleccionado,
                this.jugadorVeintiUnoSeleccionado,
                this.jugadorVeintiDosSeleccionado,
                this.jugadorVeintitresSeleccionado,
                this.jugadorVeinticuatroSeleccionado,
                this.jugadorVeinticincoSeleccionado,
                this.jugadorVeintiseisSeleccionado
            ];

            // Filtra solo los jugadores que tienen un valor no vacío
            const jugadoresNoVacios = jugadoresSeleccionados.filter(jugador => jugador !== '');

            // Comprueba si hay valores duplicados
            const jugadoresUnicos = new Set(jugadoresNoVacios);

            if (jugadoresNoVacios.length !== jugadoresUnicos.size) {
                // Si hay duplicados, muestra un mensaje de error o realiza la acción correspondiente
                this.alertMessage = 'Jugadores duplicados en el acta, revisalo.';
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                return; // Detener la ejecución si hay duplicados
            }

            // Validación del formato de resultadoPartido
            const formatoResultado = /^\d{1,2}-\d{1,2}$/;
            if (!this.resultadoPartido || !formatoResultado.test(this.resultadoPartido)) {
                this.alertMessage = 'El formato del resultado debe ser X-X, donde X es un número de 1 o 2 dígitos.';
                this.errorValidacionResultado = "Campo Obligatorio y con formato de X-X";
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                return; // Detener la ejecución si el formato es incorrecto
            }

            if(this.lugarPartido){
            // Parsear el resultado del partido
            const [golesEquipoLocal, golesEquipoRival] = this.resultadoPartido.split('-').map(num => parseInt(num, 10));
            // Validar goles según lugar del partido
            let golesCorrectos = false;
            if (this.lugarPartido === 'Casa') {
                // Si el partido es en casa:
                // La cantidad de goles en "goles" debe coincidir con los goles antes del "-"
                // La cantidad de goles en "golesRival" debe coincidir con los goles después del "-"
                if (this.goles.length === golesEquipoLocal && this.golesRival.length === golesEquipoRival) {
                    golesCorrectos = true;
                }
            } else if (this.lugarPartido === 'Fuera') {
                // Si el partido es fuera:
                // La cantidad de goles en "golesRival" debe coincidir con los goles antes del "-"
                // La cantidad de goles en "goles" debe coincidir con los goles después del "-"
                if (this.golesRival.length === golesEquipoLocal && this.goles.length === golesEquipoRival) {
                    golesCorrectos = true;
                }
            }
            // Si los goles no coinciden con el resultado
            if (!golesCorrectos) {
                this.alertMessage = 'La cantidad de goles a favor o en contra,  no coincide con el resultado ingresado.';
                this.errorValidacionResultado = "Los goles a favor o en contra no coinciden con el resultado.";
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                return; // Detener la ejecución si la validación de los goles es incorrecta
            }
            }

            if (
                this.jornadaSeleccionada &&
                this.lugarPartido &&
                this.rivalPartidoSeleccionado &&
                this.resultadoPartido &&
                this.jugadorUnoSeleccionado &&
                this.jugadorDosSeleccionado &&
                this.jugadorTresSeleccionado &&
                this.jugadorCuatroSeleccionado &&
                this.jugadorCincoSeleccionado &&
                this.jugadorSeisSeleccionado &&
                this.jugadorSieteSeleccionado &&
                this.jugadorOchoSeleccionado &&
                this.jugadorNueveSeleccionado &&
                this.jugadorDiezSeleccionado &&
                this.jugadorOnceSeleccionado &&
                this.jugadorUnoPuntuacion &&
                this.jugadorDosPuntuacion &&
                this.jugadorTresPuntuacion &&
                this.jugadorCuatroPuntuacion &&
                this.jugadorCincoPuntuacion &&
                this.jugadorSeisPuntuacion &&
                this.jugadorSietePuntuacion &&
                this.jugadorOchoPuntuacion &&
                this.jugadorNuevePuntuacion &&
                this.jugadorDiezPuntuacion &&
                this.jugadorOncePuntuacion

            ) { 
                this.limpiarValidacionesCamposFormulario();
                this.dialogRevisionActa=true;
            } else {

                if (!this.formacionEquipo) { this.errorValidacionFormacion = true; }

                if (!this.jornadaSeleccionada) {
                    this.errorValidacionJornada = true;
                }

                if (!this.lugarPartido) {
                    this.errorValidacionLugar = true;
                }

                if (!this.rivalPartidoSeleccionado) {
                    this.errorValidacionRival = true;
                }

                if (!this.resultadoPartido) {
                    this.errorValidacionResultado = "Campo Obligatorio y con formato X-X";
                }

                if (!this.jugadorUnoSeleccionado) {
                    this.errorValidacionJugadorUno = true;
                }

                if (!this.jugadorDosSeleccionado) {
                    this.errorValidacionJugadorDos = true;
                }

                if (!this.jugadorTresSeleccionado) {
                    this.errorValidacionJugadorTres = true;
                }

                if (!this.jugadorCuatroSeleccionado) {
                    this.errorValidacionJugadorCuatro = true;
                }

                if (!this.jugadorCincoSeleccionado) {
                    this.errorValidacionJugadorCinco = true;
                }

                if (!this.jugadorSeisSeleccionado) {
                    this.errorValidacionJugadorSeis = true;
                }

                if (!this.jugadorSieteSeleccionado) {
                    this.errorValidacionJugadorSiete = true;
                }

                if (!this.jugadorOchoSeleccionado) {
                    this.errorValidacionJugadorOcho = true;
                }

                if (!this.jugadorNueveSeleccionado) {
                    this.errorValidacionJugadorNueve = true;
                }

                if (!this.jugadorDiezSeleccionado) {
                    this.errorValidacionJugadorDiez = true;
                }

                if (!this.jugadorOnceSeleccionado) {
                    this.errorValidacionJugadorOnce = true;
                }

                if (!this.jugadorUnoPuntuacion) {
                    this.errorValidacionNotaUno = true;
                }

                if (!this.jugadorDosPuntuacion) {
                    this.errorValidacionNotaDos = true;
                }

                if (!this.jugadorTresPuntuacion) {
                    this.errorValidacionNotaTres = true;
                }

                if (!this.jugadorCuatroPuntuacion) {
                    this.errorValidacionNotaCuatro = true;
                }

                if (!this.jugadorCincoPuntuacion) {
                    this.errorValidacionNotaCinco = true;
                }

                if (!this.jugadorSeisPuntuacion) {
                    this.errorValidacionNotaSeis = true;
                }

                if (!this.jugadorSietePuntuacion) {
                    this.errorValidacionNotaSiete = true;
                }

                if (!this.jugadorOchoPuntuacion) {
                    this.errorValidacionNotaOcho = true;
                }

                if (!this.jugadorNuevePuntuacion) {
                    this.errorValidacionNotaNueve = true;
                }

                if (!this.jugadorDiezPuntuacion) {
                    this.errorValidacionNotaDiez = true;
                }

                if (!this.jugadorOncePuntuacion) {
                    this.errorValidacionNotaOnce = true;
                }


                // Simulación de una respuesta fallida
                this.alertMessage = 'Se ha de completar el acta de forma correcta.';
                this.alertType = 'error';
                this.estaCreandosePartido = false;
            }
        },

        async confirmacionActa() {

            // HACER LOS FETCHES NECESARIOS PARA GUARDAR TODO EN SU CORRECTO LUGAR

            try {
                this.estaCreandosePartido = true;

                const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
                //RECUPERAMOS EL CODIGO DEL USUARIO PARA LAS CONSULTAS 
                const usuarioId = sessionStorage.getItem('userId');
                const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
                if (!responseCodigo.ok) {
                    this.alertMessage = 'Error al crear el partido.';
                    this.alertType = 'error';
                    throw new Error('No se pudo obtener el código de usuario');
                } else {

                    const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

                    const jugadoresTitulares = [
                        this.jugadorUnoSeleccionado,
                        this.jugadorDosSeleccionado,
                        this.jugadorTresSeleccionado,
                        this.jugadorCuatroSeleccionado,
                        this.jugadorCincoSeleccionado,
                        this.jugadorSeisSeleccionado,
                        this.jugadorSieteSeleccionado,
                        this.jugadorOchoSeleccionado,
                        this.jugadorNueveSeleccionado,
                        this.jugadorDiezSeleccionado,
                        this.jugadorOnceSeleccionado
                    ];

                    const jugadoresSuplentes = [
                        this.jugadorDoceSeleccionado,
                        this.jugadorTreceSeleccionado,
                        this.jugadorCatorceSeleccionado,
                        this.jugadorQuinceSeleccionado,
                        this.jugadorDieciseisSeleccionado
                    ];

                    const jugadoresDesconvocados = [
                        this.jugadorDiecisieteSeleccionado,
                        this.jugadorDieciochoSeleccionado,
                        this.jugadorDiecinueveSeleccionado,
                        this.jugadorVeinteSeleccionado,
                        this.jugadorVeintiUnoSeleccionado,
                        this.jugadorVeintiDosSeleccionado
                    ];

                    const golesFormateados = this.goles.map(gol => `${gol.goleador.nombre} ${gol.minuto}'`).join(', ');
                    const asistenciasFormateadas = this.goles.map(gol => `${gol.asistente.nombre} ${gol.minuto}'`).join(', ');

                    // Obtener nombres de jugadores seleccionados
                    const nombresJugadoresTitulares = jugadoresTitulares.map(id => {
                        // Buscar jugador en el array this.jugadores por ID
                        const jugador = this.jugadores.find(jugador => jugador.id === id);
                        // Devolver el nombre del jugador o cadena vacía si no se encontró
                        return jugador ? jugador.nombre : '';
                    });

                    const nombresJugadoresSuplentes = jugadoresSuplentes.map(id => {
                        const jugador = this.jugadores.find(jugador => jugador.id === id);
                        return jugador ? jugador.nombre : '';
                    });

                    const nombresJugadoresDesconvocados = jugadoresDesconvocados.map(id => {
                        const jugador = this.jugadores.find(jugador => jugador.id === id);
                        return jugador ? jugador.nombre : '';
                    });

                    // Combinar los nombres en una sola cadena, separados por comas y espacios
                    const titularesResult = nombresJugadoresTitulares.join(', ');
                    const suplentesResult = nombresJugadoresSuplentes.join(', ');
                    const desconvocadosResult = nombresJugadoresDesconvocados.join(', ');

                    const amarillasFormateadas = this.tarjetas
                        .filter(tarjeta => tarjeta.color === '1 AMARILLA' || tarjeta.color === '2 AMARILLA')
                        .map(tarjeta => `${tarjeta.jugador.nombre} ${tarjeta.color} ${tarjeta.minuto}'`)
                        .join(', ');

                    const rojasFormateadas = this.tarjetas
                        .filter(tarjeta => tarjeta.color === 'ROJA DIRECTA')
                        .map(tarjeta => `${tarjeta.jugador.nombre} ${tarjeta.color} ${tarjeta.minuto}'`)
                        .join(', ');

                    let nuevoPartidoFormat = {
                        codigo: codigoUsuario,
                        jornada: this.jornadaSeleccionada,
                        rival: this.rivalPartidoSeleccionado,
                        resultado: this.resultadoPartido,
                        lugar: this.lugarPartido,
                        titulares: titularesResult,
                        suplentes: suplentesResult,
                        desconvocados: desconvocadosResult,
                        formacion: this.formacionEquipo,
                        goleadores: golesFormateados,
                        asistentes: asistenciasFormateadas,
                        amonestados: amarillasFormateadas,
                        expulsados: rojasFormateadas
                    }

                    //SE GUARDA EL PARTIDO COMO TAL EN TABLA PARTIDO
                    const response = await fetch(`${API_BASE_URL}/partidos`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(nuevoPartidoFormat)
                    });
                    if (response.ok) {

                        const categoria = sessionStorage.getItem('categoriaEquipo');

                        // AHORA SE ENVIAN EL RESTO DE DATOS PARA LAS LINEAS DEL PARTIDO
                        let nuevoPartidoLineasDataFormat = {
                            codigo: codigoUsuario,
                            categoria: categoria,
                            titulares: [
                                { "jugadorId": this.jugadorUnoSeleccionado, "nota": this.jugadorUnoPuntuacion },
                                { "jugadorId": this.jugadorDosSeleccionado, "nota": this.jugadorDosPuntuacion },
                                { "jugadorId": this.jugadorTresSeleccionado, "nota": this.jugadorTresPuntuacion },
                                { "jugadorId": this.jugadorCuatroSeleccionado, "nota": this.jugadorCuatroPuntuacion },
                                { "jugadorId": this.jugadorCincoSeleccionado, "nota": this.jugadorCincoPuntuacion },
                                { "jugadorId": this.jugadorSeisSeleccionado, "nota": this.jugadorSeisPuntuacion },
                                { "jugadorId": this.jugadorSieteSeleccionado, "nota": this.jugadorSietePuntuacion },
                                { "jugadorId": this.jugadorOchoSeleccionado, "nota": this.jugadorOchoPuntuacion },
                                { "jugadorId": this.jugadorNueveSeleccionado, "nota": this.jugadorNuevePuntuacion },
                                { "jugadorId": this.jugadorDiezSeleccionado, "nota": this.jugadorDiezPuntuacion },
                                { "jugadorId": this.jugadorOnceSeleccionado, "nota": this.jugadorOncePuntuacion }
                            ],
                            suplentes: [
                                { "jugadorId": this.jugadorDoceSeleccionado ? this.jugadorDoceSeleccionado : 0, "nota": this.jugadorDocePuntuacion },
                                { "jugadorId": this.jugadorTreceSeleccionado ? this.jugadorTreceSeleccionado : 0, "nota": this.jugadorTrecePuntuacion },
                                { "jugadorId": this.jugadorCatorceSeleccionado ? this.jugadorCatorceSeleccionado : 0, "nota": this.jugadorCatorcePuntuacion },
                                { "jugadorId": this.jugadorQuinceSeleccionado ? this.jugadorQuinceSeleccionado : 0, "nota": this.jugadorQuincePuntuacion },
                                { "jugadorId": this.jugadorDieciseisSeleccionado ? this.jugadorDieciseisSeleccionado : 0, "nota": this.jugadorDieciseisPuntuacion }
                            ],
                            descartes: [
                                { "jugadorId": this.jugadorDiecisieteSeleccionado ? this.jugadorDiecisieteSeleccionado : 0, "motivo": this.motivoDesconvocatoriaDiecisiete, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorDieciochoSeleccionado ? this.jugadorDieciochoSeleccionado : 0, "motivo": this.motivoDesconvocatoriaDieciocho, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorDiecinueveSeleccionado ? this.jugadorDiecinueveSeleccionado : 0, "motivo": this.motivoDesconvocatoriaDiecinueve, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeinteSeleccionado ? this.jugadorVeinteSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeinte, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeintiUnoSeleccionado ? this.jugadorVeintiUnoSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeintiUno, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeintiDosSeleccionado ? this.jugadorVeintiDosSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeintiDos, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeintitresSeleccionado ? this.jugadorVeintitresSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeintitres, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeinticuatroSeleccionado ? this.jugadorVeinticuatroSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeinticuatro, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeinticincoSeleccionado ? this.jugadorVeinticincoSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeinticinco, "jornada": this.jornadaSeleccionada },
                                { "jugadorId": this.jugadorVeintiseisSeleccionado ? this.jugadorVeintiseisSeleccionado : 0, "motivo": this.motivoDesconvocatoriaVeintiseis, "jornada": this.jornadaSeleccionada }

                            ],
                            cambios: [
                                { "jugadorEntraId": this.jugadorDoceSeleccionado ? this.jugadorDoceSeleccionado : 0, "jugadorSaleId": this.cambioJugadorDocePor ? this.cambioJugadorDocePor : 0, "minuto": this.cambioJugadorDoceMinuto ? this.cambioJugadorDoceMinuto : 0 },
                                { "jugadorEntraId": this.jugadorTreceSeleccionado ? this.jugadorTreceSeleccionado : 0, "jugadorSaleId": this.cambioJugadorTrecePor ? this.cambioJugadorTrecePor : 0, "minuto": this.cambioJugadorTreceMinuto ? this.cambioJugadorTreceMinuto : 0 },
                                { "jugadorEntraId": this.jugadorCatorceSeleccionado ? this.jugadorCatorceSeleccionado : 0, "jugadorSaleId": this.cambioJugadorCatorcePor ? this.cambioJugadorCatorcePor : 0, "minuto": this.cambioJugadorCatorceMinuto ? this.cambioJugadorCatorceMinuto : 0 },
                                { "jugadorEntraId": this.jugadorQuinceSeleccionado ? this.jugadorQuinceSeleccionado : 0, "jugadorSaleId": this.cambioJugadorQuincePor ? this.cambioJugadorQuincePor : 0, "minuto": this.cambioJugadorQuinceMinuto ? this.cambioJugadorQuinceMinuto : 0 },
                                { "jugadorEntraId": this.jugadorDieciseisSeleccionado ? this.jugadorDieciseisSeleccionado : 0, "jugadorSaleId": this.cambioJugadorDieciseisPor ? this.cambioJugadorDieciseisPor : 0, "minuto": this.cambioJugadorDieciseisMinuto ? this.cambioJugadorDieciseisMinuto : 0 }
                            ]
                        };

                        const responseLineas = await fetch(`${API_BASE_URL}/partidos/lineasData`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(nuevoPartidoLineasDataFormat)
                        });
                        if (responseLineas.ok) {

                            const data = await response.json();

                            for (const gol of this.goles) {
                                // Crear un nuevo objeto con la estructura deseada
                                let golStructureFetch = {
                                    codigo: codigoUsuario,
                                    idJugadorGol: gol.goleador.id, // Suponiendo que goleador es un objeto con un campo 'id'
                                    idRivalGol: this.rivalPartidoSeleccionado, // Suponiendo que rival es un objeto con un campo 'id'
                                    idJugadorAsistente: gol.asistente.id, // Suponiendo que asistente es un objeto con un campo 'id'
                                    jornada: this.jornadaSeleccionada,
                                    minuto: gol.minuto,
                                    descripcion: gol.descripcion
                                };

                                //  Ahora hay que enviar los goles y las tarjetas
                                const responseG = await fetch(`${API_BASE_URL}/goles`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(golStructureFetch)
                                });

                                if (responseG.ok) {
                                    const data = await responseG.json();
                                } else {
                                    this.errorMessageGoles = 'Error al crear los goles a favor.';
                                }
                            };

                            for (const golR of this.golesRival) {
                                // Crear un nuevo objeto con la estructura deseada
                                let golRStructureFetch = {
                                    codigo: codigoUsuario,
                                    idRivalGol: this.rivalPartidoSeleccionado, // Suponiendo que rival es un objeto con un campo 'id'
                                    jornada: this.jornadaSeleccionada,
                                    minuto: golR.minuto,
                                    descripcion: golR.descripcion,
                                    portero: golR.portero
                                };

                                //  Ahora hay que enviar los goles y las tarjetas
                                const responseGR = await fetch(`${API_BASE_URL}/golrival`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(golRStructureFetch)
                                });

                                if (responseGR.ok) {
                                    const data = await responseGR.json();
                                } else {
                                    this.errorMessageGolesRival = 'Error al crear los goles del rival.';
                                }
                            };

                            // Suponiendo que tienes un array de tarjetas que deseas enviar
                            this.tarjetas.forEach(async (tarjeta) => {

                                let tarjetaStructureFetch = {
                                    codigo: codigoUsuario,
                                    idJugador: tarjeta.jugador.id,
                                    jornada: this.jornadaSeleccionada,
                                    color: tarjeta.color
                                };

                                // Enviar la tarjeta al backend utilizando fetch
                                const responseT = await fetch(`${API_BASE_URL}/tarjetas`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(tarjetaStructureFetch)
                                });

                                if (responseT.ok) {
                                    const data = await responseT.json();
                                } else {
                                    console.error('Error al crear la tarjeta:', responseT.statusText);
                                }
                            });

                            const jugadoresPuntuacion = [
                                this.jugadorUnoPuntuacion,
                                this.jugadorDosPuntuacion,
                                this.jugadorTresPuntuacion,
                                this.jugadorCuatroPuntuacion,
                                this.jugadorCincoPuntuacion,
                                this.jugadorSeisPuntuacion,
                                this.jugadorSietePuntuacion,
                                this.jugadorOchoPuntuacion,
                                this.jugadorNuevePuntuacion,
                                this.jugadorDiezPuntuacion,
                                this.jugadorOncePuntuacion,
                                this.jugadorDocePuntuacion,
                                this.jugadorTrecePuntuacion,
                                this.jugadorCatorcePuntuacion,
                                this.jugadorQuincePuntuacion,
                                this.jugadorDieciseisPuntuacion
                            ];

                            const jugadoresSeleccionado = [
                                this.jugadorUnoSeleccionado,
                                this.jugadorDosSeleccionado,
                                this.jugadorTresSeleccionado,
                                this.jugadorCuatroSeleccionado,
                                this.jugadorCincoSeleccionado,
                                this.jugadorSeisSeleccionado,
                                this.jugadorSieteSeleccionado,
                                this.jugadorOchoSeleccionado,
                                this.jugadorNueveSeleccionado,
                                this.jugadorDiezSeleccionado,
                                this.jugadorOnceSeleccionado,
                                this.jugadorDoceSeleccionado,
                                this.jugadorTreceSeleccionado,
                                this.jugadorCatorceSeleccionado,
                                this.jugadorQuinceSeleccionado,
                                this.jugadorDieciseisSeleccionado
                            ];

                            for (let i = 0; i < jugadoresPuntuacion.length; i++) {
                                const idJugador = jugadoresSeleccionado[i];

                                // Si idJugador es nulo, vacío o 0, continuar con la siguiente iteración
                                if (!idJugador) {
                                    continue;
                                }

                                // Crear un nuevo objeto con la estructura deseada
                                const notaStructureFetch = {
                                    codigo: codigoUsuario,
                                    idJugador: jugadoresSeleccionado[i],
                                    jornada: this.jornadaSeleccionada,
                                    nota: jugadoresPuntuacion[i] || 'NP'
                                };

                                const responseN = await fetch(`${API_BASE_URL}/notas`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(notaStructureFetch)
                                });

                                if (responseN.ok) {
                                    const data = await responseN.json();
                                } else {
                                    this.errorMessageGoles = 'Error al crear la nota.';
                                }
                            };

                            // Simulación de una respuesta exitosa
                            this.limpiarValoresCamposFormulario();
                            this.limpiarValidacionesCamposFormulario();
                            this.alertMessage = 'Acta de partido creada exitosamente.';
                            this.alertType = 'success';
                            this.estaCreandosePartido = false;
                            this.dialogRevisionActa = false;

                        } else {
                            this.alertMessage = 'Error al crear las lineas del partido.';
                            this.alertType = 'error';
                            this.estaCreandosePartido = false;
                            this.dialogRevisionActa = false;
                        }
                    } else {
                        this.alertMessage = 'Error al crear el partido.';
                        this.alertType = 'error';
                        this.estaCreandosePartido = false;
                        this.dialogRevisionActa = false;
                    }
                }
            } catch (error) {
                this.alertMessage = 'Error al conectar con la API.';
                this.alertType = 'error';
                this.estaCreandosePartido = false;
                this.dialogRevisionActa = false;
            }
        },

        agregarGol() {
            this.errorMessageGoles = '';

            const noAsistente = {
                id: 0,
                nombre: 'NADIE',
                apellido: 'NADIE',
                apodo: 'NADIE'
            };

            const rival = {
                id: 0,
                nombre: 'RIVAL (autogol)',
                apellido: 'RIVAL (autogol)',
                apodo: 'RIVAL (autogol)'
            };

            // Validar que los campos no estén vacíos
            if ((this.nuevoGol.goleador !== null && this.nuevoGol.goleador !== undefined && this.nuevoGol.goleador !== "") &&
                (this.nuevoGol.asistente !== null && this.nuevoGol.asistente !== undefined && this.nuevoGol.asistente !== "") &&
                this.nuevoGol.minuto && this.nuevoGol.descripcion) {

                // Validar que el goleador y el asistente no sean el mismo, a menos que ambos sean 0
                if (this.nuevoGol.goleador === this.nuevoGol.asistente && this.nuevoGol.goleador !== 0) {
                    this.errorMessageGoles = 'Un jugador no puede marcar y asistir en el mismo gol.';
                    return; // Detener la ejecución si la validación falla
                }

                // Validar que el minuto es un número entero entre 1 y 99, sin letras u otros caracteres
                const minuto = this.nuevoGol.minuto;
                const minutoValido = /^[1-9][0-9]?$/.test(minuto); // Expresión regular para validar números enteros entre 1 y 99

                if (!minutoValido) {
                    this.errorMessageGoles = 'El minuto debe ser un número entero entre 1 y 99, sin letras u otros caracteres.';
                    return; // Detener la ejecución si la validación falla
                }

                // Convertir goleador a objeto si es 0
                if (this.nuevoGol.goleador === 0) {
                    this.nuevoGol.goleador = rival;
                } else {
                    const jugadorSeleccionadoGol = this.jugadores.find(jugador => jugador.id === this.nuevoGol.goleador);
                    this.nuevoGol.goleador = jugadorSeleccionadoGol;
                }

                // Convertir asistente a objeto si es 0
                if (this.nuevoGol.asistente === 0) {
                    this.nuevoGol.asistente = noAsistente;
                } else {
                    const jugadorSeleccionadoAsistencia = this.jugadores.find(jugador => jugador.id === this.nuevoGol.asistente);
                    this.nuevoGol.asistente = jugadorSeleccionadoAsistencia;
                }

                // Añadir el nuevo gol al array de goles
                this.goles.push({
                    goleador: this.nuevoGol.goleador,
                    asistente: this.nuevoGol.asistente,
                    minuto: this.nuevoGol.minuto,
                    descripcion: this.nuevoGol.descripcion

                });

                // Limpiar los campos de input
                this.nuevoGol.goleador = '';
                this.nuevoGol.asistente = '';
                this.nuevoGol.minuto = '';
                this.nuevoGol.descripcion = '';
            } else {
                this.errorMessageGoles = 'Se han de rellenar los campos obligatorios.';
            }
        },

        agregarGolRival() {
            this.errorMessageGolesRival = '';

            // Validar que los campos no estén vacíos
            if (this.nuevoGolRival.minuto && this.nuevoGolRival.descripcion) {

                // Validar que el minuto es un número entero entre 1 y 99, sin letras u otros caracteres
                const minuto = this.nuevoGolRival.minuto;
                const minutoValido = /^[1-9][0-9]?$/.test(minuto); // Expresión regular para validar números enteros entre 1 y 99

                if (!minutoValido) {
                    this.errorMessageGolesRival = 'El minuto debe ser un número entero entre 1 y 99, sin letras u otros caracteres.';
                    return; // Detener la ejecución si la validación falla
                }

                // Añadir el nuevo gol al array de goles
                this.golesRival.push({
                    minuto: this.nuevoGolRival.minuto,
                    descripcion: this.nuevoGolRival.descripcion,
                    portero: this.nuevoGolRival.portero
                });

                // Limpiar los campos de input
                this.nuevoGolRival.minuto = '';
                this.nuevoGolRival.descripcion = '';
                this.nuevoGolRival.portero = '';

            } else {
                this.errorMessageGolesRival = 'Se han de rellenar los campos obligatorios.';
            }
        },

        agregarTarjeta() {

            this.errorMessageTarjetas = '';
            // Validar que los campos no estén vacíos
            if (this.nuevaTarjeta.color && this.nuevaTarjeta.jugador && this.nuevaTarjeta.minuto) {

                // Validar que el minuto es un número entero entre 1 y 99, sin letras u otros caracteres
                const minuto = this.nuevaTarjeta.minuto;
                const minutoValido = /^[1-9][0-9]?$/.test(minuto); // Expresión regular para validar números enteros entre 1 y 99

                if (!minutoValido) {
                    this.errorMessageTarjetas = 'El minuto debe ser un número entero entre 1 y 99, sin letras u otros caracteres.';
                    return; // Detener la ejecución si la validación falla
                }

                // Busca el objeto del jugador seleccionado
                const jugadorSeleccionado = this.jugadores.find(jugador => jugador.id === this.nuevaTarjeta.jugador);
                
                // Validar cuántas tarjetas tiene ya el jugador
                const tarjetasJugador = this.tarjetas.filter(tarjeta => tarjeta.jugador.id === jugadorSeleccionado.id);

                if (tarjetasJugador.length >= 2) {
                    this.errorMessageTarjetas = 'Este jugador ya tiene dos tarjetas, no se le puede asignar una tercera.';
                    return; // Detener la ejecución si la validación falla
                }   
                
                // Validar si ya existe una tarjeta con el mismo color y jugador
                const tarjetaDuplicada = this.tarjetas.some(tarjeta =>
                    tarjeta.jugador.id === jugadorSeleccionado.id && tarjeta.color === this.nuevaTarjeta.color
                );

                if (tarjetaDuplicada) {
                    this.errorMessageTarjetas = 'Ya está registrada esa tarjeta para ese jugador.';
                    return; // Detener la ejecución si la validación falla
                }
                
                // Asigna el objeto del jugador seleccionado al modelo nuevaTarjeta.jugador
                this.nuevaTarjeta.jugador = jugadorSeleccionado;

                // Añadir el nuevo gol al array de goles
                this.tarjetas.push({
                    color: this.nuevaTarjeta.color,
                    jugador: this.nuevaTarjeta.jugador,
                    minuto: this.nuevaTarjeta.minuto
                });
                // Limpiar los campos de input
                this.nuevaTarjeta.color = '';
                this.nuevaTarjeta.jugador = '';
                this.nuevaTarjeta.minuto = '';
            } else {
                this.errorMessageTarjetas = 'Se han de rellenar los campos obligatorios.';
            }
        },

        limpiarValoresCamposFormulario() {
            this.jornadaSeleccionada = '';
            this.rivalPartidoSeleccionado = '';
            this.resultadoPartido = '';
            this.lugarPartido = '';
            this.formacionEquipo = '';
            this.jugadorUnoSeleccionado = '';
            this.jugadorDosSeleccionado = '';
            this.jugadorTresSeleccionado = '';
            this.jugadorCuatroSeleccionado = '';
            this.jugadorCincoSeleccionado = '';
            this.jugadorSeisSeleccionado = '';
            this.jugadorSieteSeleccionado = '';
            this.jugadorOchoSeleccionado = '';
            this.jugadorNueveSeleccionado = '';
            this.jugadorDiezSeleccionado = '';
            this.jugadorOnceSeleccionado = '';
            this.jugadorDoceSeleccionado = '';
            this.jugadorTreceSeleccionado = '';
            this.jugadorCatorceSeleccionado = '';
            this.jugadorQuinceSeleccionado = '';
            this.jugadorDieciseisSeleccionado = '';
            this.jugadorDiecisieteSeleccionado = '';
            this.jugadorDieciochoSeleccionado = '';
            this.jugadorDiecinueveSeleccionado = '';
            this.jugadorVeinteSeleccionado = '';
            this.jugadorVeintiUnoSeleccionado = '';
            this.jugadorVeintiDosSeleccionado = '';
            this.jugadorVeintitresSeleccionado = '';
            this.jugadorVeinticuatroSeleccionado = '';
            this.jugadorVeinticincoSeleccionado = '';
            this.jugadorVeintiseisSeleccionado = '';
            this.cambioJugadorDocePor = '';
            this.cambioJugadorTrecePor = '';
            this.cambioJugadorCatorcePor = '';
            this.cambioJugadorQuincePor = '';
            this.cambioJugadorDieciseisPor = '';
            this.cambioJugadorDoceMinuto = 0;
            this.cambioJugadorTreceMinuto = 0;
            this.cambioJugadorCatorceMinuto = 0;
            this.cambioJugadorQuinceMinuto = 0;
            this.cambioJugadorDieciseisMinuto = 0;
            this.motivoDesconvocatoriaDiecisiete = 0;
            this.motivoDesconvocatoriaDieciocho = 0;
            this.motivoDesconvocatoriaDiecinueve = 0;
            this.motivoDesconvocatoriaVeinte = 0;
            this.motivoDesconvocatoriaVeintiUno = 0;
            this.motivoDesconvocatoriaVeintiDos = 0;
            this.motivoDesconvocatoriaVeintitres = 0;
            this.motivoDesconvocatoriaVeinticuatro = 0;
            this.motivoDesconvocatoriaVeinticinco = 0;
            this.motivoDesconvocatoriaVeintiseis = 0;
            this.jugadorUnoPuntuacion = '';
            this.jugadorDosPuntuacion = '';
            this.jugadorTresPuntuacion = '';
            this.jugadorCuatroPuntuacion = '';
            this.jugadorCincoPuntuacion = '';
            this.jugadorSeisPuntuacion = '';
            this.jugadorSietePuntuacion = '';
            this.jugadorOchoPuntuacion = '';
            this.jugadorNuevePuntuacion = '';
            this.jugadorDiezPuntuacion = '';
            this.jugadorOncePuntuacion = '';
            this.jugadorDocePuntuacion = '';
            this.jugadorTrecePuntuacion = '';
            this.jugadorCatorcePuntuacion = '';
            this.jugadorQuincePuntuacion = '';
            this.jugadorDieciseisPuntuacion = '';


            this.goles = [];
            this.golesRival = [];
            this.tarjetas = [];


        },

        limpiarValidacionesCamposFormulario() {

            this.errorValidacionJornada = false;
            this.errorValidacionLugar = false;
            this.errorValidacionRival = false;
            this.errorValidacionResultado = false;
            this.errorValidacionFormacion = false;

            this.errorValidacionJugadorUno = false;
            this.errorValidacionJugadorDos = false;
            this.errorValidacionJugadorTres = false;
            this.errorValidacionJugadorCuatro = false;
            this.errorValidacionJugadorCinco = false;
            this.errorValidacionJugadorSeis = false;
            this.errorValidacionJugadorSiete = false;
            this.errorValidacionJugadorOcho = false;
            this.errorValidacionJugadorNueve = false;
            this.errorValidacionJugadorDiez = false;
            this.errorValidacionJugadorOnce = false;

            this.errorValidacionNotaUno = false;
            this.errorValidacionNotaDos = false;
            this.errorValidacionNotaTres = false;
            this.errorValidacionNotaCuatro = false;
            this.errorValidacionNotaCinco = false;
            this.errorValidacionNotaSeis = false;
            this.errorValidacionNotaSiete = false;
            this.errorValidacionNotaOcho = false;
            this.errorValidacionNotaNueve = false;
            this.errorValidacionNotaDiez = false;
            this.errorValidacionNotaOnce = false;
            this.errorValidacionNotaDoce = false;
            this.errorValidacionNotaTrece = false;
            this.errorValidacionNotaCatorce = false;
            this.errorValidacionNotaQuince = false;
            this.errorValidacionNotaDieciseis = false;

        },

        async getCodigoUsuarioLoguedIn() {

            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const usuarioId = sessionStorage.getItem('userId');

            const response = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!response.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await response.text(); // Suponiendo que el código de usuario es un texto simple

            return codigoUsuario;
        },

        async cargarRivales() {

            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const codigoUsuario = await this.getCodigoUsuarioLoguedIn();

            // Realizar la petición GET a la API de rivales
            fetch(`${API_BASE_URL}/rivales/${codigoUsuario}/codigo`)
                .then(response => response.json())
                .then(data => {
                    // Asignar los datos a la propiedad 'rivales'
                    this.rivales = data;
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                });

        },

        async cargarjugadores() {

            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const codigoUsuario = await this.getCodigoUsuarioLoguedIn();

            // Realizar la petición GET a la API de jugadores
            // Usar el código de usuario para hacer la solicitud GET a los jugadores
            const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
            if (!responseJugadores.ok) {
                throw new Error('No se pudo obtener la lista de jugadores');
            }
            const data = await responseJugadores.json();

            // Asignar los datos a la propiedad 'jugadores'
            this.jugadores = data;

            // Hacer una copia de 'data' y asignarla a 'goleadores'
            this.goleadores = JSON.parse(JSON.stringify(data));
            this.goleadores.push({
                id: 0,
                nombre: 'RIVAL (autogol)',
                apellido: 'RIVAL (autogol)',
                apodo: 'RIVAL (autogol)'
            });

            // Hacer una copia de 'data' y asignarla a 'asistentes'
            this.asistentes = JSON.parse(JSON.stringify(data));
            this.asistentes.push({
                id: 0,
                nombre: 'NADIE',
                apellido: 'NADIE',
                apodo: 'NADIE'
            });

        },
    },
    created() {
        // Cargar los rivales cuando se crea el componente
        this.cargarRivales();
        this.cargarjugadores();
        this.limpiarValidacionesCamposFormulario();
    }
};
</script>


<style scoped>
/* Puedes agregar estilos específicos para tu componente aquí */
.required-asterisk {
    font-size: 1.8em;
    color: red;
}

.v-row.mb-small {
    margin-bottom: 2px;
    /* Puedes ajustar este valor según tus necesidades */
}
</style>