<template>
  <div>
    <v-card-title class="headline">
      Seguimiento de Entrenamientos
    </v-card-title>
    
    <v-container>
      <v-row>
        <!-- Tarjeta con la media global de valoraciones -->
        <v-col cols="12">
          <v-card class="pa-4 text-center">
            <v-card-subtitle>
              Media de todos los entrenamientos realizados
            </v-card-subtitle>
            <div v-if="valoracionGlobal > 0">
              <v-chip :color="getColor(valoracionGlobal)" large dark>
                {{ valoracionGlobal.toFixed(2) }}
              </v-chip>
            </div>
            <div v-else>
              Aún no hay entrenamientos
            </div>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row>
        <!-- Tabla con los días de la semana y la valoración media -->
        <v-col cols="12">
          <v-data-table :headers="headers" :items="diasSemanaValoracionMedia" class="elevation-1">
            <template v-slot:item.valoracionMedia="{ item }">
              <div v-if="item.valoracionMedia > 0">
                <v-chip :color="getColor(item.valoracionMedia)" dark>
                  {{ item.valoracionMedia.toFixed(2) }}
                </v-chip>
              </div>
              <div v-else>
                Sin entrenamientos
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entrenamientos: [],
      diasSemana: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
      headers: [
        { text: "Día de la Semana", value: "diaSemana" },
        { text: "Valoración Media", value: "valoracionMedia" },
      ],
    };
  },
  computed: {
    diasSemanaValoracionMedia() {
      return this.diasSemana.map(dia => {
        const entrenamientosDelDia = this.entrenamientos.filter(entrenamiento => entrenamiento.diaSemana === dia);
        const totalValoraciones = entrenamientosDelDia.reduce((total, ent) => total + ent.valoracion, 0);
        const valoracionMedia = entrenamientosDelDia.length ? totalValoraciones / entrenamientosDelDia.length : 0;

        return {
          diaSemana: dia,
          valoracionMedia,
        };
      });
    },
    valoracionGlobal() {
      const totalValoraciones = this.entrenamientos.reduce((total, ent) => total + ent.valoracion, 0);
      return this.entrenamientos.length ? totalValoraciones / this.entrenamientos.length : 0;
    }
  },
  methods: {
    async cargarEntrenamientos() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      const usuarioId = sessionStorage.getItem("userId");

      try {
        const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!responseCodigo.ok) {
          throw new Error("No se pudo obtener el código de usuario");
        }

        const codigoUsuario = await responseCodigo.text();

        // Realizar la petición GET para obtener entrenamientos
        const responseEntrenamientos = await fetch(`${API_BASE_URL}/entrenamiento/codigo/${codigoUsuario}`);
        if (!responseEntrenamientos.ok) {
          throw new Error("No se pudieron obtener los entrenamientos");
        }

        const data = await responseEntrenamientos.json();
        this.entrenamientos = data;
      } catch (error) {
        console.error("Error al cargar entrenamientos:", error);
      }
    },
    getColor(valoracion) {
      if (valoracion < 5) return 'red';
      else if (valoracion < 6) return 'grey';
      else if (valoracion < 8) return 'blue';
      else return 'green';
    },
  },
  mounted() {
    this.cargarEntrenamientos();
  },
};
</script>

<style scoped>
/* Aquí puedes agregar estilos personalizados */
</style>
