<template>
  <v-container>
    <!-- Fila 1: Estado de forma y tablas -->
    <v-row>
      <!-- Columna 1: -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4 text-white" outlined elevation="5" color="indigo">
          <v-card-title class="text-center">GLOBAL</v-card-title>
          <v-card-text>
            <v-simple-table class="indigo no-hover">
              <thead>
                <tr>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-medal</v-icon>
                      </template>
                      <span>Victorias</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-shield-alt</v-icon>
                      </template>
                      <span>Empates</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-times-circle</v-icon>
                      </template>
                      <span>Derrotas</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center table-text">
                    <v-chip color="green">{{ totalData.V }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="yellow">{{ totalData.E }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="red">{{ totalData.D }}</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Columna 2: -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4 text-white" outlined elevation="5" color="indigo">
          <v-card-title class="text-center">LOCAL</v-card-title>
          <v-card-text>
            <v-simple-table class="indigo no-hover">
              <thead>
                <tr>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-medal</v-icon>
                      </template>
                      <span>Victorias</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-shield-alt</v-icon>
                      </template>
                      <span>Empates</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-times-circle</v-icon>
                      </template>
                      <span>Derrotas</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center table-text">
                    <v-chip color="green">{{ localData.V }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="yellow">{{ localData.E }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="red">{{ localData.D }}</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Columna 3:  -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4 text-white" outlined elevation="5" color="indigo">
          <v-card-title class="text-center">VISITANTE</v-card-title>
          <v-card-text>
            <v-simple-table class="indigo no-hover">
              <thead>
                <tr>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-medal</v-icon>
                      </template>
                      <span>Victorias</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-shield-alt</v-icon>
                      </template>
                      <span>Empates</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" v-bind="attrs" v-on="on">fas fa-times-circle</v-icon>
                      </template>
                      <span>Derrotas</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center table-text">
                    <v-chip color="green">{{ visitanteData.V }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="yellow">{{ visitanteData.E }}</v-chip>
                  </td>
                  <td class="text-center table-text">
                    <v-chip color="red">{{ visitanteData.D }}</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- FILA 2   -->
    <v-row>
      <v-col cols="8"><!-- Columna 1:  -->
        <v-row>
          <v-col cols="6"><!-- Columna 1 A  -->
            <v-card class="pa-4 mb-4" outlined elevation="5">
              <v-card-text>
                <div class="text-center mt-46">
                  <div class="text-h6 ">PARTIDOS DISPUTADOS</div>
                  <div class="text-h2 font-weight-bold">{{ totalPartidos }}</div>
                  <v-icon>fa-regular fa-futbol</v-icon>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6"><!-- Columna 1 B  -->
            <v-card class="pa-4 mb-4" outlined elevation="5">
              <v-card-title class="text-h5">
                VALORACIÓN MEDIA
              </v-card-title>
              <v-card-text>
                <div class="text-center mt-46">
                  <div>{{ this.rating }}/5</div>
                  <v-rating hover readonly half-increments v-model="rating" background-color="indigo lighten-3" color="indigo" large></v-rating>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><!-- Columna 1 B  -->
            <v-card class="mx-auto text-center" color="indigo" dark>
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline :value="value" color="white" height="100" padding="24" stroke-linecap="round" smooth>
                    <template v-slot:label="item">
                      {{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>
              <v-card-text>
                <div class="text-h3 font-weight-thin">
                  PTS POR PARTIDO
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Columna 3: Estado de forma detallado -->
      <v-col cols="4">
        <v-card outlined elevation="5" color="indigo" class="text-white">
          <v-card-title class="text-center">HISTORICO</v-card-title>
          <v-card-text>
            <v-timeline align-top dense>
              <v-timeline-item fill-dot right v-for="partido in partidos" :key="partido.resultado"
                :color="getResultColor(partido.result)" small>
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ partido.jornada }} - {{ partido.lugar }} </strong>
                  </div>
                  <div>{{ partido.resultado }} {{ partido.rival }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      codigoUsuarioFiltro: '',
      value: [],
      partidos: [],
      totalPartidos: '',
      rating: 0,

      results: ['Victoria', 'Empate', 'Derrota', 'Victoria', 'Derrota', 'Victoria', 'Victoria', 'Victoria', 'Victoria', 'Victoria', 'Victoria', 'Victoria',], // Ejemplo de resultados, ajusta según tus necesidades

      // Ejemplo de datos para las tablas, ajusta según tus necesidades
      totalData: {
        V: 0,
        E: 0,
        D: 0,
      },
      localData: { V: 0, E: 0, D: 0 },
      visitanteData: { V: 0, E: 0, D: 0 }
    };
  },
  methods: {
    getResultColor(result) {
      // Método para asignar colores según el resultado
      switch (result) {
        case 'Victoria':
          return 'green'; // Color verde para victoria
        case 'Empate':
          return 'yellow'; // Color amarillo para empate
        case 'Derrota':
          return 'red'; // Color rojo para derrota
        default:
          return 'grey'; // Color gris por defecto
      }
    },

    //COMIENZAN LOS FETCHES Y LA LOGICA PARA CARGAR LAS VARIABLES

    async getCodigoUsuario() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      const usuarioId = sessionStorage.getItem('userId');

      const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
      if (!responseCodigo.ok) {
        throw new Error('No se pudo obtener el código de usuario');
      }
      const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

      this.codigoUsuarioFiltro = codigoUsuario;
    },

    async cargarNotaMediaEquipo() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      //Necesito que me devuelva un número entero del 1 al 5

      // Realizar la petición GET a la API 
      const responseNotaMedia = await fetch(`${API_BASE_URL}/inicioMenu/notaMedia/${this.codigoUsuarioFiltro}`);
      if (!responseNotaMedia.ok) {
        alert("No se puedo obtener la nota media, esta fallando su grafica.")
        throw new Error('No se pudo obtener la nota media del equipo');
      }

      const data = await responseNotaMedia.json();
      // Asignar los datos a la propiedad 'rating' data debera de ser un numero entero ente 0-5
      this.rating = data;
    },

    async cargarPartidos() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      // Realizar la petición GET a la API 
      const responsePartidos = await fetch(`${API_BASE_URL}/inicioMenu/partidos/${this.codigoUsuarioFiltro}`);
      if (!responsePartidos.ok) {
        alert("No se puedo obtener los partidos, esta fallando su grafica.")
        throw new Error('No se pudo obtener los partidos del equipo');
      }

      const data = await responsePartidos.json();
      // Asignar los datos a la propiedad 'rating' data debera de ser un numero entero ente 0-5
      this.partidos = data;
      this.totalPartidos = data.length;
      
      // Inicializar contador de resultados
      this.totalData = {
        V: 0,
        E: 0,
        D: 0
      };
      this.localData = {
        V: 0,
        E: 0,
        D: 0
      };
      this.visitanteData = {
        V: 0,
        E: 0,
        D: 0
      };

      this.value = [];

      // Contar resultados
      data.forEach(partido => {
        switch (partido.result) {
          case 'Victoria':
            this.totalData.V++;
            this.value.push(3);
            break;
          case 'Empate':
            this.totalData.E++;
            this.value.push(1);
            break;
          case 'Derrota':
            this.totalData.D++;
            this.value.push(0);
            break;
        }
      });

      // Contar resultados
      data.forEach(partido => {
        switch (partido.result) {
          case 'Victoria':
            if (partido.lugar === 'Casa') {
              this.localData.V++;
            } else {
              this.visitanteData.V++;
            }
            break;
          case 'Empate':
            if (partido.lugar === 'Casa') {
              this.localData.E++;
            } else {
              this.visitanteData.E++;
            }
            break;
          case 'Derrota':
            if (partido.lugar === 'Casa') {
              this.localData.D++;
            } else {
              this.visitanteData.D++;
            }
            break;
        }
      });
    },

  },
  async mounted() {
    //AQUI SE HA DE LLAMAR A TODOS LOS METODOS QUE HACEN LOS FETCHES PARA CARGAR LAS VARIABLES
    await this.getCodigoUsuario();
    await this.cargarNotaMediaEquipo();
    await this.cargarPartidos(); //del array de objetos de partidos lo saco todo lo demás

  },
};
</script>

<style scoped>
.no-hover tr:hover {
    background-color: inherit !important;
  }

.v-card-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

.table-text {
  font-size: 16px;
  /* Ajusta el tamaño de fuente según tus preferencias */
}

.text-white {
  color: white !important;
}

.v-card .v-card-title,
.v-card .v-card-text,
.v-card .v-timeline-item {
  color: white !important;
}
</style>