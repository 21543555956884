<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-card-title class="blue darken-2 white--text text-center">Iniciar Sesión</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="username" label="Nombre de Usuario"></v-text-field>
              <v-text-field v-model="password" label="Contraseña" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="login" color="green darken-2">Iniciar Sesión</v-btn>
            <v-btn @click="goToRegister" color="blue darken-2">Registrarme</v-btn>
          </v-card-actions>
        </v-card>

        <!-- Alerta de advertencia -->
        <v-alert v-if="warningMessage"  type="warning">
          {{ warningMessage }}
        </v-alert>

        <!-- Alerta de éxito -->
        <v-alert v-if="successMessage"  type="success">
          {{ successMessage }}
        </v-alert>

        <!-- Alerta de error -->
        <v-alert v-if="errorMessage"  type="error">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      successMessage: '',
      errorMessage: '',
      warningMessage: '',
    };
  },
  methods: {
    async login() {

      // Limpiar todos los mensajes al hacer clic en Iniciar Sesión
      this.warningMessage = '';
      this.successMessage = '';
      this.errorMessage = '';

      // Validación de campos vacíos
      if (!this.username || !this.password) {
        this.warningMessage = "Todos los campos deben estar rellenados.";
        this.successMessage = "";
        this.errorMessage = "";
        return; // Detiene la ejecución del método si hay campos vacíos
      }

      const credentials = {
        username: this.username,
        password: this.password
      };

      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/usuarios/checkLogIn`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        });

        if (response.ok) {


          // Inicio de sesión exitoso, procesa la respuesta para obtener el userId
          const data = await response.json(); // Parsea la respuesta del servidor
          const userId = data.userId; // Obtiene el userId de la respuesta

          // Realiza un fetch adicional para obtener el rol del usuario
          const rolResponse = await fetch(`${API_BASE_URL}/usuarios/${userId}/getRolType`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (rolResponse.ok) {
            const rol = await rolResponse.text(); // Obtiene el rol del usuario como texto

            // Guarda el userId y el rol en el sessionStorage
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('usernameLogedIn', credentials.username);
            sessionStorage.setItem('isSomeoneLogedIn', true);
            sessionStorage.setItem('rolUserLogedIn', rol);

            // Muestra mensaje de éxito
            this.successMessage = "Inicio de sesión exitoso";
            this.errorMessage = ""; // Limpia el mensaje de error si existe
            console.log('Inicio de sesión exitoso, rol:', rol);

            this.$router.push({ name: 'Plantilla' });

            // En algún lugar de tu código donde quieras forzar un refresh
            window.location.reload();

          } else {
            this.errorMessage = "Error al recuperar el rol.";
            this.successMessage = ""; // Limpia el mensaje de éxito si existe
          }
        } else {
          // Inicio de sesión fallido, muestra mensaje de error
          this.errorMessage = "Inicio de sesión fallido";
          this.successMessage = ""; // Limpia el mensaje de éxito si existe
        }
      } catch (error) {
        // Error al realizar la solicitud, muestra mensaje de error
        this.errorMessage = "Error al realizar la solicitud";
        this.successMessage = ""; // Limpia el mensaje de éxito si existe
      }
    },

    goToRegister(){
      this.$router.push({ name: 'Register'  });
    },
  },
};
</script>

<style scoped>
/* Agrega estilos personalizados según sea necesario */
</style>
