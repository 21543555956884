<template>
  <v-container>
    <v-alert ref="alert"  :value="true" v-model="alert.show" :type="alert.type">
      {{ alert.message }}
    </v-alert>
    <v-data-table :headers="headers" :items="usuarios" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-btn @click="redirigirAActualizar(item.id)" icon>
              <v-icon>fas fa-pencil-alt</v-icon>
            </v-btn>
            <v-btn @click="redirigirAObservar(item.id)" icon>
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
            <v-btn @click="mostrarConfirmacionEliminar(item.id)" icon>
              <v-icon>fas fa-trash-alt</v-icon>
            </v-btn>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.apellido }}</td>
          <td>{{ item.username }}</td>
          <td>{{ '*'.repeat(item.password.length) }}</td>
          <td>{{ item.fechaRegistro }}</td>
          <td>{{ item.fechaUltimaModificacion }}</td>
        </tr>
      </template>
    </v-data-table>
    <!-- Modal de Confirmación para Eliminar -->

    <confirmacion-eliminar-usuario :showDialog="confirmacionEliminar" :closeDialog="cerrarConfirmacionEliminar"
      :confirmarAccionUsuario="eliminarUsuario" :mensajeConfirmacion="mensajeConfirmacion" />

  </v-container>
</template>



<script>

import ConfirmacionEliminarUsuario from '../../components/ConfirmacionEliminar.vue';


export default {
  components: {
    ConfirmacionEliminarUsuario,
  },
  data() {
    return {
      headers: [
        { text: 'Acciones', value: 'acciones', sortable: false },
        { text: 'ID', value: 'id' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Apellido', value: 'apellido' },
        { text: 'Username', value: 'username' },
        { text: 'Contraseña', value: 'password' },
        { text: 'Fecha de Creación', value: 'fechaRegistro' },
        { text: 'Fecha de Modificación', value: 'fechaUltimaModificacion' },
      ],
      usuarios: [],
      confirmacionEliminar: false,
      usuarioAEliminarId: null,
      mensajeConfirmacion: '',
      alert: {
        show: false,
        type: 'success', // Puedes cambiar esto dinámicamente según el resultado
        message: '',
      },
    };
  },
  methods: {
    success(message) {
      this.alert.show = true;
      this.alert.type = 'success';
      this.alert.message = message;
    },
    error(message) {
      this.alert.show = true;
      this.alert.type = 'error';
      this.alert.message = message;
    },
    redirigirAActualizar(id) {
      // Redirige a la página de actualización con el ID del usuario seleccionado
      this.$router.push({ name: 'ActualizarUsuario', params: { id } });
    }, redirigirAObservar(id) {
      // Redirige a la página de actualización con el ID del usuario seleccionado
      this.$router.push({ name: 'ObservarUsuario', params: { id } });
    },
    mostrarAlerta(accion, id) {
      alert(`Botón ${accion} clickeado para el usuario con ID ${id}`);
    },
    mostrarConfirmacionEliminar(id) {
      this.usuarioAEliminarId = id;
      this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el usuario con ID ${id}?`;
      this.confirmacionEliminar = true;
    },
    cerrarConfirmacionEliminar() {
      this.usuarioAEliminarId = null;
      this.mensajeConfirmacionEliminar = '';
      this.confirmacionEliminar = false;
    },
    eliminarUsuario() {
      // Almacena el ID antes de realizar la petición
      const idAEliminar = this.usuarioAEliminarId;

      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      // Realizar la petición DELETE a la API para eliminar el usuario
      fetch(`${API_BASE_URL}/usuarios/borrar/${this.usuarioAEliminarId}`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            // Usuario eliminado correctamente
            this.success(`Usuario con ID ${idAEliminar} eliminado correctamente.`);
          } else {
            // Ocurrió un error al eliminar el usuario
            this.error(`Error al eliminar el usuario con ID ${idAEliminar}, este ya debió de haber sido eliminado previamente.`);
          }
        })
        .catch(error => {
          console.error('Error al realizar la solicitud DELETE:', error);
          // Mostrar mensaje de error en caso de fallo en la solicitud
          this.error('Error al realizar la solicitud DELETE. Por favor, intenta nuevamente.');
        })
      .finally(() => {
        // Cerrar el modal después de eliminar o en caso de error
        this.cerrarConfirmacionEliminar();
        // Recargar la lista de usuarios después de eliminar
        this.cargarUsuarios();
      });
    },
    cargarUsuarios() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      // Realizar la petición GET a la API de usuarios
      fetch(`${API_BASE_URL}/usuarios`)
        .then(response => response.json())
        .then(data => {
          // Asignar los datos a la propiedad 'usuarios'
          this.usuarios = data;
        })
        .catch(error => {
          console.error('Error al cargar los datos:', error);
        });
    },
  },
  mounted() {
    this.cargarUsuarios();
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
