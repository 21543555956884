import Vue from 'vue';
import Router from 'vue-router';
import LoginUsuario from '../views/LoginUsuario';
import homeComponent from '../components/Home';
import ListarAllUsuarios from '../views/Usuarios/ListarAllUsuarios';
import RegisterUsuario from '../views/Usuarios/RegisterUsuario';
import Actualizar from '../views/Usuarios/ActualizarUsuario';
import Observar from '../views/Usuarios/ObservarUsuario';
import ListarAllJugadores from '../views/Jugadores/ListarAllJugadores';
import CrearJugador from '../views/Jugadores/CrearJugador';
import ObservarJugador from '../views/Jugadores/ObservarJugador';
import ModificarJugador from '../views/Jugadores/ModificarJugador';
import NuevoActa from '../views/ActaPartido/ActaDePartido.vue';
import NuevoActaPartidoOcho from '../views/ActaPartido/ActaDeFutbolOcho.vue';
import ListarAllRivales from '../views/Rivales/ListarAllRivales.vue';
import CrearRival from '../views/Rivales/CrearRival.vue';
import ListarAllDesconvocatorias from '../views/Desconvocatorias/ListarAllDesconvocatorias.vue';
import CrearEquipo from '../views/Equipos/CrearEquipo.vue';
import ActualizarEquipo from '../views/Equipos/ModificarEquipo.vue';
import ObservarEquipo from '../views/Equipos/ObservarEquipo.vue';
import ListarAllEquipos from '../views/Equipos/ListarAllEquipos.vue';
import InicioMenu from '../views/MenuIzq/InicioMenu.vue';
import GolesYAsist from '../views/MenuIzq/GolesAsistsMenu.vue';
import AnalisisGoles from '../views/MenuIzq/AnalisisGolesMenu.vue';
import GolesFav from '../views/MenuIzq/GolesFavorMenu.vue';
import GolesCon from '../views/MenuIzq/GolesContraMenu.vue';
import Porteria from '../views/MenuIzq/PorteriaMenu.vue';
import Sanciones from '../views/MenuIzq/SancionesMenu.vue';
import StatsJugador from '../views/MenuIzq/StatsJugadorMenu.vue';
import ListadoPartidosRegistrados from '../views/MenuIzq/ListadoPartidosRegistradosMenu.vue';
import Informes from '../views/MenuIzq/Informes/ListadoInformes.vue';
import CrearInforme from '../views/MenuIzq/Informes/CrearInforme.vue';
import ActualizarInforme from '../views/MenuIzq/Informes/ActualizarInforme.vue';
import ObservarInforme from '../views/MenuIzq/Informes/ObservarInforme';
import NuevoEntreno from '../views/ActaEntreno/NuevoEntreno.vue';
import AsistenciaEnt from '../views/ActaEntreno/AsistenciaEnt.vue';
import SeguimientoEnt from '../views/ActaEntreno/SeguimientoEnt.vue';
import HistoricoEnt from '../views/ActaEntreno/HistoricoEnt.vue';


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/', redirect: '/LogIn'
    },
    { 
      path: '/Home', 
      name: 'Home', 
      component: homeComponent, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/LogIn', 
      name: 'LogIn', 
      component: LoginUsuario 
    },
    { 
      path: '/Register', 
      name: 'Register', 
      component: RegisterUsuario 
    },
    { 
      path: '/Usuarios', 
      name: 'Usuarios', 
      component: ListarAllUsuarios,
      meta: { requiresAuth: true, requiresAdmin: true } 
    },
    { 
      path: '/ActualizarUsuario', 
      name: 'ActualizarUsuario', 
      component: Actualizar,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ObservarUsuario', 
      name: 'ObservarUsuario', 
      component: Observar,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/Plantilla', 
      name: 'Plantilla', 
      component: ListarAllJugadores,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/CrearJugador', 
      name: 'CrearJugador', 
      component: CrearJugador,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ObservarJugador', 
      name: 'ObservarJugador', 
      component: ObservarJugador,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ModificarJugador', 
      name: 'ModificarJugador', 
      component: ModificarJugador,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/CrearEquipo', 
      name: 'CrearEquipo', 
      component: CrearEquipo, 
    },
    { 
      path: '/Equipos', 
      name: 'Equipos', 
      component: ListarAllEquipos,
      meta: { requiresAuth: true, requiresAdmin: true } 
    },
    { 
      path: '/ActualizarEquipo', 
      name: 'ActualizarEquipo', 
      component: ActualizarEquipo,
      meta: { requiresAuth: true, requiresAdmin: true } 
    },
    { 
      path: '/ObservarEquipo', 
      name: 'ObservarEquipo', 
      component: ObservarEquipo,
      meta: { requiresAuth: true, requiresAdmin: true } 
    },
    { 
      path: '/Rivales', 
      name: 'Rivales', 
      component: ListarAllRivales,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/CrearRival', 
      name: 'CrearRival', 
      component: CrearRival,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/Desconvocatorias', 
      name: 'Desconvocatorias', 
      component: ListarAllDesconvocatorias,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/NuevoEntrenamiento', 
      name: 'NuevoEntrenamiento', 
      component: NuevoEntreno,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/AsistenciaEnt', 
      name: 'AsistenciaEnt', 
      component: AsistenciaEnt,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/SeguimientoEnt', 
      name: 'SeguimientoEnt', 
      component: SeguimientoEnt,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/HistoricoEnt', 
      name: 'HistoricoEnt', 
      component: HistoricoEnt,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/NuevoActa', 
      name: 'NuevoActa', 
      component: NuevoActa,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/NuevoActaPartidoOcho', 
      name: 'NuevoActaPartidoOcho', 
      component: NuevoActaPartidoOcho,
      meta: { requiresAuth: true } 
    },
    { 
      path: '/InicioMenu', 
      name: 'InicioMenu', 
      component: InicioMenu, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/GolesYAsist', 
      name: 'GolesYAsist', 
      component: GolesYAsist, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/GolesFav', 
      name: 'GolesFav', 
      component: GolesFav, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/GolesCon', 
      name: 'GolesCon', 
      component: GolesCon, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/AnalisisGoles', 
      name: 'AnalisisGoles', 
      component: AnalisisGoles, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/Porteria', 
      name: 'Porteria', 
      component: Porteria, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/Sanciones', 
      name: 'Sanciones', 
      component: Sanciones, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/StatsJugador', 
      name: 'StatsJugador', 
      component: StatsJugador, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ListadoPartidosRegistrados', 
      name: 'ListadoPartidosRegistrados', 
      component: ListadoPartidosRegistrados, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/Informes', 
      name: 'Informes', 
      component: Informes, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/CrearInforme', 
      name: 'CrearInforme', 
      component: CrearInforme, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ActualizarInforme', 
      name: 'ActualizarInforme', 
      component: ActualizarInforme, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/ObservarInforme', 
      name: 'ObservarInforme', 
      component: ObservarInforme, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '*', 
      redirect: '/' 
    },
  ]
});

router.beforeEach((to, from, next) => {
  // Verifica si la ruta requiere autenticación
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const userId = sessionStorage.getItem('userId');

    if (userId) {
      // Verifica si la ruta requiere acceso exclusivo del usuario con ID 0
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        // Solo permite el acceso si el usuario es el administrador (ID 0)
        if (userId === '0') {
          next();  // Usuario con ID 0, permite el acceso
        } else {
          next({ name: 'Home' });  // Redirige a Home si no tiene acceso
        }
      } else {
        // Si no requiere acceso exclusivo, permite el paso a la siguiente ruta
        next();
      }
    } else {
      // El usuario no está autenticado, redirige al inicio de sesión
      next({ name: 'LogIn' });
    }
  } else {
    // La ruta no requiere autenticación
    next();
  }
});

export default router;
